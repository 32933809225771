.modal {
    justify-content: center;
    align-items: center;
    display: none;
    opacity: 0;
    visibility: hidden;

    &.show {
        display: flex!important;
        opacity: 1;
        visibility: visible;
    }

    .modal-header {
        background: $black;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .close {
            width: 42px;
            height: 42px;
            border-radius: none;
            cursor: pointer;
            background: $black;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            margin-right: 22px;

            path {
                stroke: $white;
            }
        }
    }

    .modal-body {
        padding-left: 22px;
        padding-right: 22px;
        margin-bottom: 16px;
    }

    .price-container {
        display: flex;
        flex-direction: column-reverse;

        .price {
            color: $red;
            font-family: $font-bold;
            font-size: 25px;
            line-height: 38px;
        }

        .old-price {
            font-size: 18px;
            line-height: 27px;
            text-decoration-line: line-through;
            color: $gray;
        }
    }

    .modal-dialog {
        background: $white;
        padding: 0;
    }
    
    .modal-footer {
        display: flex;
        justify-content: center;
        padding-bottom: 22px;

        .close {
            cursor: pointer;
            //@include button;

            &:hover {
                border: 1px solid $black;
            }
        }
    }
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
  }
  
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  .fade {
    transition: opacity .15s linear;
  }
  
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
  
    &.fade {
      opacity: 0;
    }
  
    &.show {
      opacity: .5;
    }
}

.alert {
    position: fixed;
    bottom: 30px;
    right: 100px;
    width: 100%;
    max-width: 300px;
    padding: 24px 22px 24px 16px;
    border: none;
    border-radius: 0;
    color:#fff;
    background: $notification;
    opacity: 0;
    visibility: hidden;
    transform: translate(0px,40px);
    transition: opacity .3s, visibility .3s, transform .3s;
    @include media-breakpoint-up(sm) {
        max-width: 700px;
    }
    p {
        margin: 0;
        display: block;
        
        @include media-breakpoint-up(sm) {
            display: inline;
        }

        a {
            margin-left: 0;
            text-decoration: underline;
        }
    }

    a {
        font-family: $font-bold;
        color:#fff;
        display: inline-block;
        margin-top: 16px;
        background: linear-gradient(0deg, #fff, #fff) no-repeat right bottom / 0 var(--bg-h);
        @include hoverBase;
        
        @include media-breakpoint-up(sm) {
            display: inline;
            margin: 0 0 0 32px;
        }

        &:hover {
            color:#fff;
        }
    }

    button {
        margin-left: auto;
        background:none;
        outline:none;
        border:none;
    }

    &.show {
        visibility: visible;
        opacity: 1;
        transform: translate(0px,0px);
    }

    &.error {
        background: $red2;   
    }
}