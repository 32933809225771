#footer {
  background-color: $black;
  padding-top: 40px;

  a,
  h2 {
    color: $white;
  }

  h2 {
    text-transform: uppercase;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 32px;
  }

  

  .info {
    margin-right: auto;

    .footer-logo {
      margin-bottom: 32px;
      display: block;
      &:hover {
        background: none;
      } 
    }

    a {
      line-height: 24px;
    }
  }

  .single-column {
    margin-right: 98px;
    padding-left: 16px;
    padding-right: 16px;

    &:last-of-type {
      margin-right: 0;
    }
    p {
      margin-top: 24px;
      font-size: 14px;
      line-height: 16px;

      &:first-of-type {
        margin-top: 0;
      }
    }
  }

  .separator {
    color: $white;
    margin-left: 8px;
    margin-right: 8px;
  }

  .top-footer {
    margin-bottom: 72px;

    a {
      @include whiteHover;
    }
  }

  .bottom-footer {
    padding-bottom: 32px;
    
    .line {
      height: 1px;
      background-color: $gray;
      margin-bottom: 32px;
    }

    * {
      color: $gray;
      font-size: 14px;
      line-height: 16px;
    }

    a {
      @include grayHover;
    }
  }
}