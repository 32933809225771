#cookie-consent {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10000;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;

  .wrapper {
    background: #fff;
    max-width: 765px;
    width: 100%;
    z-index: 10000;
    color: $black;
    font-style: normal;
    font-size: 12px;
    padding: 24px;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }

  .cookie-consent-header {
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #d1d1d1;

    .title {
      display: flex;
      justify-content: space-between;
    }

    .main-cookie-title {
      margin-bottom: 12px;
    }

    button {
      width: 24px;
      height: 24px;
      background: none;
      border: none;
      cursor: pointer;
      align-items: center;
      justify-content: center;
    }
  }

  p {
    font-size: 18px;
    font-family: $font-primary;

    a {
      color: $orange;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .cookie-consent-options {
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #d1d1d1;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    font-size: 18px;
    font-family: $font-primary;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    .input {
      display: flex;
      align-items: center;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }


    button {
      border: none;
      padding: 12px 26px;
      margin-bottom: 12px;
      @include button($orange, $white, $red, $white);
      border: 1px solid $orange;

      display: inline-block;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }
  }
}

