.main-navi-wrapper {
    background-color: $lightgray3;
    padding-top: 36px;
    padding-bottom: 19px;

    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    z-index: 1000;
    transition: all 0.3s ease;

    &.open {
        left: 0;
    }

    @include media-breakpoint-up(lg) {
        padding-top: 19px;
        position: relative;
        top: unset;
        left: 0;
        height: auto;
        width: auto;
    }

    .mobile-acc {
        display: flex;
        justify-content: flex-end;

        .acc-item {
            margin-right: 24px;
            text-align: center;
            position: relative;
            @include blackHover;

            @include media-breakpoint-up(lg) {
                margin-right: 32px;
            }

            &:last-of-type {
                margin-right: 0;
            }

            .count {
                position: absolute;
                width: 20px;
                height: 20px;
                content: '';
                background-color: $orange;
                border-radius: 50%;
                top: -10px;
                right: -3px;
                color: $white;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 10px;
                line-height: 14px;
            }

        }
    }
}

#main-navi {
    ul {
        li {
            list-style-type: none;

            span {
                font-family: $font-bold;
            }
        }
    }

    height: 85vh;
    overflow-x: auto;
    margin-top: 24px;

    @include media-breakpoint-up(lg) {
        margin-top: 0;
        height: auto;
        overflow-x: unset;
    }


    ul.level1 {
        display: block;
        margin: 0;
        padding: 0;

        @include media-breakpoint-up(lg) {
            display: flex;
        }
    }

    li.level1 {

        position: relative;

        @include media-breakpoint-up(lg) {
            margin-right: 64px;
        }

        a.level1 {
            color: $black;
            @include orangeHover;
        }

        &:last-of-type {
            margin-right: 0;
        }

        &:hover {
            a.level1 {
                color: $orange;
            }
        }

        .show-more {
            width: 8px;
            height: 8px;
            border-bottom: 2px solid $black;
            border-right: 2px solid $black;
            transform: rotate(45deg);
            position: absolute;
            right: -14px;
            display: block;
            top: 5px;
        }

        &.open {

            a.level1 {
                color: $orange;
                background-size: 100% var(--bg-h);
                background-position-x: left;
            }

            .show-more {
                border-bottom: 2px solid $orange;
                border-right: 2px solid $orange;
                transform: rotate(-135deg);
                top: 9px;
            }
        }

        &.vsi-artikli {
            .show-more {
                display: none;

                @include media-breakpoint-up(lg) {
                    display: block;
                }
            }

            a.level1 {
                display: none;

                @include media-breakpoint-up(lg) {
                    display: block;
                }
            }

            .level2-wrapper {
                .show-more {
                    display: block;
                }
            }

            &:hover {
                .level2-wrapper {
                    display: block;
                }
            }

            .level3-wrapper {
                @include media-breakpoint-up(lg) {
                    height: 594px;
                    overflow-x: hidden;
                    overflow-y: auto;
                    margin-top: 27px;

                    &::-webkit-scrollbar {
                        width: 5px;
                    }

                    &::-webkit-scrollbar-track {
                        background: #f1f1f1;
                    }

                    &::-webkit-scrollbar-thumb {
                        background: $orange;
                    }

                    &::-webkit-scrollbar-thumb:hover {
                        background: $red;
                    }
                }
            }
        }

        &.stresni-sistemi {
            .show-more {
                display: none;
            }

            .level2-wrapper {
                display: none;
            }
        }

        &:not(:first-child) {
            a {
                padding-top: 16px;
                padding-bottom: 16px;
                display: block;
                background: none;

                @include media-breakpoint-up(lg) {
                    padding-top: 0;
                    padding-bottom: 0;
                    @include orangeHover;
                }
            }
        }

        &.active {
            a {
                color: $orange;
                background-size: 100% var(--bg-h);
                background-position-x: left;
            }
        }


        &.aktualno,
        &.nase-storitve,
        &.ceniki {
            a {
                color: $gray;
            }
        }
    }

    .level2-wrapper {


        @include media-breakpoint-up(lg) {
            position: absolute;

            z-index: 1;
            top: 50px;
            top: 16px;
            left: -32px;
            min-width: 288px;
            display: none;
            padding-top: 27px;
        }

        .inner {
            @include media-breakpoint-up(lg) {
                background-color: $orange;
            }
        }

        ul.level2 {

            margin: 0;
            padding: 0;


            @include media-breakpoint-up(lg) {
                display: flex;
                flex-direction: column;
                width: 100%;
            }
        }

        &.open {
            display: flex;
        }
    }

    li.level2 {
        position: relative;

        @include media-breakpoint-up(lg) {
            position: unset;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row;
            width: 100%;
        }

        &:hover {
            .level3-wrapper {
                display: block;
            }

            background-color: $red;
        }

        .show-more {

            top: 16px !important;
            right: 20px !important;
            transform: rotate(-45deg) !important;

            width: 20px;
            height: 20px;
            //position: absolute;
            display: flex !important;
            justify-content: center;
            align-items: center;
            border: none !important;
            z-index: 5;

            &::after {
                width: 8px;
                height: 8px;
                content: '';
                display: inline-block;
                border-bottom: 2px solid $orange !important;
                border-right: 2px solid $orange !important;

                @include media-breakpoint-up(lg) {
                    display: none;
                }
            }

            @include media-breakpoint-up(lg) {
                top: 20px !important;
                border-bottom: 2px solid $white !important;
                border-right: 2px solid $white !important;
                width: 8px;
                height: 8px;
                position: unset;
                margin-right: 20px;
            }
        }

        a.level2 {
            padding-top: 15px;
            padding-bottom: 15px;
            display: block;
            position: relative;

            @include media-breakpoint-up(lg) {
                padding-left: 32px;
            }

            span {
                color: $orange;

                @include media-breakpoint-up(lg) {
                    color: $white;
                    --bg-h: 1px;
                    transition: background-size 300ms;
                    padding-bottom: 2px;
                    background: linear-gradient(0deg, #fff, #fff) no-repeat right bottom / 0 var(--bg-h);
                }
            }

            &:hover {
                span {
                    @include media-breakpoint-up(lg) {
                        background-size: 100% var(--bg-h);
                        background-position-x: left;
                    }
                }
            }
        }

        &:last-of-type {
            border-top: 1px solid $orange;

            @include media-breakpoint-up(lg) {
                border-top: 1px solid $white;
            }

            &:hover {
                .level3-wrapper {
                    display: none;
                }
            }
        }


        &.vse-blagovne-znamke {
            .show-more {
                display: none !important;

                @include media-breakpoint-up(lg) {
                    display: block !important;
                }
            }

            span {
                position: relative;

                color: $black;

                @include media-breakpoint-up(lg) {
                    color: $white;
                }

                &:after {
                    position: absolute;
                    right: -30px;
                    content: '';
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_446_7032)'%3E%3Cpath d='M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z' fill='%23e87722'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_446_7032'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A") no-repeat center center / cover;

                    @include media-breakpoint-up(lg) {
                        display: none;
                    }
                }
            }
        }
    }

    .level3-wrapper {
        //display: block;
        position: fixed;
        left: 100%;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: $white;
        z-index: 10;
        transition: all 0.3s ease;
        padding-left: 16px;
        padding-top: 24px;
        background-color: $lightgray3;
        overflow-x: auto;
        top: 84px;
        height: 88vh;
        padding-bottom: 48px;

        @include media-breakpoint-up(lg) {
            position: absolute;
            overflow-x: unset;
            display: none;
            background-color: $white;
            height: auto;
            padding: 32px;
            top: 0;
            //width: 1291px;
            width: 640px;
            min-height: 520px;
            height: auto;
            padding-bottom: 32px;

            -webkit-box-shadow: 9px 9px 26px 0px rgba(0, 0, 0, 0.24);
            -moz-box-shadow: 9px 9px 26px 0px rgba(0, 0, 0, 0.24);
            box-shadow: 9px 9px 26px 0px rgba(0, 0, 0, 0.24);
        }

        @media screen and (min-width:1160px) {
            width: 864px;
        }

        @media screen and (min-width:1600px) {
            width: 1298px;
        }

        .back {
            margin-bottom: 56px;
            font-family: $font-bold;
            position: relative;
            display: flex;
            align-items: center;
            cursor: pointer;

            @include media-breakpoint-up(lg) {
                display: none;
            }

            &:before {
                content: '';
                display: inline-block;
                width: 24px;
                height: 24px;
                transform: rotate(180deg);
                margin-right: 16px;
                background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_446_7032)'%3E%3Cpath d='M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z' fill='%2302020a'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_446_7032'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A") no-repeat center center / cover;
            }
        }

        .title {
            margin-bottom: 24px;
            color: $orange;
            font-family: $font-bold;
            font-size: 20px;
            line-height: 24px;

            @include media-breakpoint-up(lg) {
                display: none;
            }
        }

        &.open {
            left: 0;
        }

        ul.level3 {
            padding-left: 0px;

            @include media-breakpoint-up(lg) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                //width: 1298px;
                position: relative;
                width: 630px;
            }

            @media screen and (min-width:1160px) {
                width: 802px;
                grid-template-columns: repeat(3, 1fr);
            }

            @media screen and (min-width:1600px) {
                width: 1280px;
                grid-template-columns: repeat(4, 1fr);
            }
        }

        li.level3 {
            @include media-breakpoint-up(lg) {
                background-color: $white;
                flex-grow: 1;
                max-width: 250px;
                width: 100%;
            }

            a {
                span {
                    color: $black;
                }
            }

            .show-more {
                display: none !important;
            }
        }

        a.level3 {
            padding-top: 15px;
            padding-bottom: 15px;
            display: block;

            span {
                @include media-breakpoint-up(lg) {
                    @include blackHover;
                }
            }
        }
    }

    ul.level4 {

        @include media-breakpoint-up(lg) {
            padding-left: 0;
        }
    }

    li.level4 {
        a.level4 {
            @include blackHover;
            padding-top: 8px;
            padding-bottom: 8px;
            display: flex;

            span {
                font-family: $font-primary;
            }
        }
    }
}

#pwaBtn {
    position: relative;
    cursor: pointer;
    @include orangeHover;

    .dl-icon {
        position: absolute;
        margin-left: 5px;
    }

    &:hover {
        color: $orange;

        .dl-icon {
            background: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 10V6H9V10H6L10 14L14 10H11Z' fill='%23e87722'/%3E%3C/svg%3E%0A") no-repeat center center/cover;
        }
    }
}