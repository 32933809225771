.product-header {
  display: none;
  @include media-breakpoint-up(xl) {
    font-size: 14px;
    display: grid;
    grid-template-columns: 28% 7% 8% 7% 7% 7% 7% 10% 15%;
    align-items:center;
    border-bottom: 1px solid #BA5F1B;
  }

  @media screen and (min-width:1504px) {
    grid-template-columns: 22% 5% 9% 7% 6% 6% 7% 10% 28%;
  }

  > div {
    padding: 14px 16px;
  }
}
.product-grid {
  margin-bottom: 124px;
  @include media-breakpoint-up(xl) {
    margin-bottom: 96px;
  }

  &.first-page {
    .product-single {
      &:first-of-type {
        background-color: rgba($orange, 0.2);
      }
    }
  }

  .product-single {
    font-size: 14px;
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    border-bottom: 1px solid #c3c3c3;
    
    @include media-breakpoint-up(xl) {
      align-items:center;
      grid-template-columns: 28% 7% 8% 7% 7% 7% 7% 10% 15%;
      grid-template-rows: 1fr;
    }

    @media screen and (min-width:1504px) {
      grid-template-columns: 22% 5% 9% 7% 6% 6% 7% 10% 28%;
    }

    > div {
      padding: 22px 16px;

      .serial {
        font-size: 16px;
        font-family: $font-bold;
        
        .serial-title {
          font-family: $font-primary;
        }
      }

      &.quantity {
        @include media-breakpoint-down(lg) {
          grid-column-end: span 2;
          display: flex;
        }
        input {
          width: 100%;
          display: block;
          border: 1px solid $gray;
          height: 48px;
          padding: 14px 16px;
        }

        position: relative;

        &:hover {
          @include media-breakpoint-up(md) {
            .help-tooltip {
              left: -78px;
              top: 85px;
              display: block;
            }
          }
        }
      }

      &.buttons {
        display: flex;
        justify-content: flex-start;
        @include media-breakpoint-up(md) {
          justify-content: flex-end;
        }

        .order {
          .order-add {
            .icon-cart-plus {
              background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1724_21137)'%3E%3Cpath d='M9.00039 16.2001L4.80039 12.0001L3.40039 13.4001L9.00039 19.0001L21.0004 7.0001L19.6004 5.6001L9.00039 16.2001Z' fill='%23ffffff'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1724_21137'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A") no-repeat center center / cover;
            }
          }
        }

        .inquiry {
          .inquiry-add {
            .icon-inquiry {
              background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1724_21137)'%3E%3Cpath d='M9.00039 16.2001L4.80039 12.0001L3.40039 13.4001L9.00039 19.0001L21.0004 7.0001L19.6004 5.6001L9.00039 16.2001Z' fill='%2302020a'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1724_21137'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A") no-repeat center center / cover;
            }

            &:hover {
              .icon-inquiry {
                background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1724_21137)'%3E%3Cpath d='M9.00039 16.2001L4.80039 12.0001L3.40039 13.4001L9.00039 19.0001L21.0004 7.0001L19.6004 5.6001L9.00039 16.2001Z' fill='%23ffffff'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1724_21137'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A") no-repeat center center / cover!important;
              }
            }
          }
        }
        

        .btn {
          padding: 0px;
          width: 48px;
          height: 48px;
          border-radius: 50%;
          line-height: 55px;
          
          @media screen and (min-width: 1505px) {
            border-radius: 0;
            padding: 9px 20px 8px;
            width: auto;
            display: flex;
          }
          
          &.inquiry {
            margin-left: 16px;
            .icon-inquiry {
              transition: all 0.3s ease;
            }
            
            &:hover {
              .icon-inquiry {
                background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_37_14134)'%3E%3Cpath d='M3 4C3 2.9 3.89 2 5 2H14V4H5V18H9.83L10.41 18.58L12 20.17L13.58 18.59L14.17 18H19V10H21V18C21 19.1 20.1 20 19 20H15L12 23L9 20H5C3.89 20 3 19.1 3 18V4Z' fill='%23ffffff'/%3E%3Cpath d='M24 5V3H21V0H19V3H16V5H19V8H21V5H24Z' fill='%23ffffff'/%3E%3Cpath d='M13 15H11V17H13V15Z' fill='%23ffffff'/%3E%3Cpath d='M14 9C14 7.9 13.1 7 12 7C10.9 7 10 7.9 10 9H8C8 6.79 9.79 5 12 5C14.21 5 16 6.79 16 9C16 11.5 13 11.75 13 14H11C11 10.75 14 11 14 9Z' fill='%23ffffff'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_37_14134'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A") no-repeat center center/cover;
              }
            }
          }
          
          span {
            white-space: nowrap;
            &.text {
              display: none;
              @media screen and (min-width: 1505px) {
                display: inline;
              }                
            }
            
            
            &.icon {
              @media screen and (min-width: 1505px) {
                margin-left: 8px;
              }
            }
          }
        }
      }
      
    }

    div [class*="title"] {
      @include media-breakpoint-down(lg) {
        margin-bottom: 16px;
        color:#868686;
      }
    }

    .stock {
      position:relative;
      > div {
        &.onstock {
          color:$green;
        }

        &.nostock {
          color:$red2;
        }
      }
    }

    &:hover {
      background:#F9FBFC;

      .buttons {
        display: flex;
        .btn {
        }
      }
    }
  }
}

.price-loader {
  @include loader;
  .inner {
    width: 30px;
    height: 30px;

    &::before ,
    &::after {
      border-width: 3px;
    }
  }
}

.price-loader-white {
  @include loaderWhite;
  .inner {
    width: 30px;
    height: 30px;

    &::before ,
    &::after {
      border-width: 3px;
    }
  }
}
