.home-brand-logos {
  position: relative;
  z-index: 1;
  
  padding-top: 0;
  padding-top:24px;
  padding-bottom:24px;
  //margin-bottom: 64px;
  background-color: $lightgray4;
  margin-left: 0;
  margin-right: 0;
  margin-top: 40px;

  @include media-breakpoint-up(lg) {
    padding-top: 28px;
    padding-bottom: 28px;
    //margin-bottom: 96px;
    height: 110px;
  }

  .container-xl {
    padding-right: 36px;
  }
}

#brand-logos {

  .splide__list {
    align-items: center;

    .splide__slide {
      img {
        margin: 0 auto;
        object-fit: contain;
        max-height: 50px;
      }
    }
  }
}

.visual-sale {
  margin-bottom: 64px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 96px;
  }

  .visual-sale-element {
    position: relative;
    overflow: hidden;

    .sale-link {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    // &:before {
    //   width: 80%;
    //   height: 1000px;
    //   content: '';
    //   background-color: $orange;
    //   left: 0;
    //   top: -80%;
    //   transform: rotate(84deg);
    //   z-index: 1;
    //   position: absolute;
    //   display: none;

    //   @media screen and (min-width:540px) {
    //     top: -130%;
    //   }

    //   @media screen and (min-width:665px) {
    //     top: -145%;
    //   }

    //   @media screen and (min-width:740px) {
    //     top: -160%;
    //   }

    //   @media screen and (min-width:830px) {
    //     top: -175%;
    //   }

    //   @media screen and (min-width:830px) {
    //     top: -70%;
    //     left: -50%;
    //   }

    //   @media screen and (min-width:992px) {
    //     top: -70%;
    //     left: -35%;
    //   }

    //   @media screen and (min-width:1270px) {
    //     top: -70%;
    //     left: -30%;
    //   }
    // }

    // .img {
    //   min-height: 350px;
    // }

    video {
      max-height: 376px;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    .visual-sale-content {
      position: absolute;
      top: 16px;
      z-index: 2;
      left: 16px;

      @include media-breakpoint-up(lg) {
        top: 50%;
        transform: translate(0%, -50%);
        left: 32px;
      }

      .content-edit {
        color: $white;
        margin-bottom: 32px;

        h2 {
          font-size: 48px;
          line-height: 58px;

          @include media-breakpoint-up(lg) {
            font-size: 80px;
            line-height: 96px;
          }
        }
        p {
          margin-top: 0;
          font-family: $font-bold;
          font-size: 24px;
          line-height: 26px;

          @include media-breakpoint-up(lg) {
            font-size: 38px;
            line-height: 44px;
          }
        }

        img {
          //max-height: 120px;
          object-fit: cover;
          width: auto;
          height: 100%;
          margin-bottom: 24px;
        }
      }
    }
  }
}

.home-news {
  position: relative;
  z-index: 10;
  padding-bottom: 96px;

  .container-xl {
    position: relative;
    z-index: 2;
  }

  
  @include media-breakpoint-up(lg) {
    padding-bottom: 120px;
  }

  .content-top {
    margin-bottom: 8px;

    .container-xl {
      &.content {
        padding: 0;
      }
    }
  }
}

.home {
  #footer {
    padding-bottom: 93px;

    @include media-breakpoint-up(lg) {
      padding-bottom: 0;
    }
  }
}

