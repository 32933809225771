body {
  .user-account {
    padding-bottom: 120px;

    .user-account-title {
      font-family: $font-bold;
      font-size: 37px;
      line-height: 41px;
      margin-bottom: 32px;

      @include media-breakpoint-up(lg) {
        font-size: 64px;
        line-height: 77px;
        margin-bottom: 48px;
      }
    }

    h2 {
      font-family: $font-primary;
      font-size: 25px;
      line-height: 29px;
      letter-spacing: 1px;
    }

    .address-elements {
      padding-bottom: 30px;
    }

    .profile-navi-wrapper {

      .mobile-navi {
        font-size: 21px;
        line-height: 30px;
        padding-top: 20px;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 20px;
        margin-bottom: 48px;
        background-color: $lightgray5;
        display: flex;
        justify-content: space-between;
        position: relative;
        align-items: center;

        &.open {
          display: none;
        }
      }

      .profile-navi-expand {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        position: absolute;
        top: 12px;
        right: 28px;
        z-index: 100;
        cursor: pointer;

        .icon-expand {
          transform: rotate(90deg);
        }

        &.open {
          .icon-expand {
            transform: rotate(0deg);
          }
        }
      }
    }

    .profile-navi {
      background-color: $lightgray5;
      padding-top: 40px;
      padding-left: 24px;
      padding-right: 24px;
      padding-bottom: 40px;
      margin-top: 0;
      margin-bottom: 48px;
      display: none;

      @include media-breakpoint-up(lg) {
        display: block;
      }

      &.open {
        display: block;
      }


      li {
        list-style-type: none;
        margin-bottom: 16px;

        &:first-of-type {
          padding-bottom: 40px;
          margin-bottom: 40px;
          border-bottom: 1px solid $lightgray6;
        }

        &:nth-child(5) {
          padding-bottom: 40px;
          margin-bottom: 40px;
          border-bottom: 1px solid $lightgray6;
        }

        a {
          color: $black;
          font-size: 21px;
          line-height: 30px;
          text-decoration: none;

          @include blackHover;

          &.active {
            font-family: $font-bold;
            background-size: 100% var(--bg-h);
            background-position-x: left;
          }
        }
      }

      .acc-logout {
        border-top: 1px solid $lightgray6;
        padding-top: 40px;
        margin-top: 40px;

        a {
          position: relative;

          &:after {
            content: '';
            display: inline-block;
            width: 24px;
            height: 24px;
            position: absolute;
            top: 1px;
            right: -25px;
            background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1101_30057)'%3E%3Cpath d='M11 7L9.6 8.4L12.2 11H2V13H12.2L9.6 15.6L11 17L16 12L11 7ZM20 19H12V21H20C21.1 21 22 20.1 22 19V5C22 3.9 21.1 3 20 3H12V5H20V19Z' fill='%2302020A'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1101_30057'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A") no-repeat center center / cover;
          }
        }
      }
    }

    .control-panel {
      .quick-navi {
        row-gap: 32px;
        margin-bottom: 96px;

        .quick-navi-element {
          //border: 1px solid $lightgray6;
          height: 282px;

          a {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $lightgray6;
            transition: all 0.3s ease;

            &:hover {
              background-color: $lightgray4;

              h2 {
                background-size: 100% var(--bg-h);
                background-position-x: left;
                color: $orange;
              }
            }
          }

          h2 {
            font-family: $font-primary;
            font-size: 16px;
            line-height: 22px;
            color: $black;
            font-weight: 400;
            @include orangeHover;

            @include media-breakpoint-up(lg) {
              font-size: 21px;
              line-height: 30px;
            }
          }

          .inner {
            text-align: center;

            .icon {
              width: 48px;
              height: 48px;

              @include media-breakpoint-up(lg) {
                width: 80px;
                height: 80px;
              }
            }
          }

          &.obligations-info-container {
            .price-loader-white {
              display: flex;
              justify-content: center;
              margin-bottom: 24px;
            }

            a {
              position: relative;
              background-color: $orange;
              border: 1px solid $orange;

              &:after {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                content: '';
                background-color: rgba($black, 0.2);
                z-index: 1;
                opacity: 0;
                transition: all 0.3s ease;
              }

              &:hover {
                background-color: $orange;

                &:after {
                  opacity: 1;
                }
              }
            }

            .to-pay,
            .limit {
              font-size: 24px;
              line-height: 28px;
              color: $white;

              @include media-breakpoint-up(lg) {
                font-size: 38px;
                line-height: 44px;
              }
            }

            .to-pay {
              font-family: $font-bold;
            }

            .limit {
              font-family: $font-primary;
              opacity: 0.5;
              margin-bottom: 8px;

              .separator {
                margin-right: 8px;
              }
            }

            .info {
              color: $white;
              max-width: 300px;

              @include media-breakpoint-up(lg) {
                font-size: 21px;
                line-height: 30px;
              }
            }
          }
        }
      }

      .frequently-purchased-products {
        position: relative;

        .frequently-purchased-products-dynamic {
          min-height: 150px;
        }
      }
    }

    .orders {
      margin-bottom: 96px;

      .title {
        margin-bottom: 24px;

        h2 {
          font-size: 30px;
          line-height: 34px;

          @include media-breakpoint-up(lg) {
            font-size: 50px;
            line-height: 57px;
          }
        }

        a {
          font-family: $font-bold;
          color: $black;
          padding-right: 30px;
          position: relative;

          &:after {
            position: absolute;
            right: 5px;
            top: -1px;
            content: '';
            display: inline-block;
            width: 24px;
            height: 24px;
            transition: all 0.3s ease;
            background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_446_7032)'%3E%3Cpath d='M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z' fill='%2302020A'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_446_7032'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A") no-repeat center center / cover;
          }

          &:hover {
            &:after {
              right: 0;
            }
          }
        }
      }
    }

    .order-grid {
      display: grid;
      grid-template-columns: 45% 32% 23%;

      @media screen and (min-width:800px) {
        grid-template-columns: 18% 17% 17% 18% 15% 15%;
      }

      @include media-breakpoint-up(lg) {
        grid-template-columns: 45% 32% 23%;
      }

      @include media-breakpoint-up(xl) {
        grid-template-columns: 18% 17% 17% 18% 15% 15%;
      }

      .order-nr {
        grid-column: 1;
        grid-row: 1;

        @media screen and (min-width:800px) {
          grid-column: 1;
          grid-row: 1;
        }

        @include media-breakpoint-up(lg) {
          grid-column: 1;
          grid-row: 1;
        }

        @include media-breakpoint-up(xl) {
          grid-column: 1;
          grid-row: 1;
        }
      }

      .order-date {
        grid-column: 2;
        grid-row: 1;

        @media screen and (min-width:800px) {
          grid-column: 2;
          grid-row: 1;
        }

        @include media-breakpoint-up(lg) {
          grid-column: 2;
          grid-row: 1;
        }

        @include media-breakpoint-up(xl) {
          grid-column: 2;
          grid-row: 1;
        }
      }

      .order-dateupdated {
        grid-column: 3;
        grid-row: 1;

        @media screen and (min-width:800px) {
          grid-column: 3;
          grid-row: 1;
        }

        @include media-breakpoint-up(lg) {
          grid-column: 3;
          grid-row: 1;
        }

        @include media-breakpoint-up(xl) {
          grid-column: 3;
          grid-row: 1;
        }
      }

      .order-status {
        grid-column: 1;
        grid-row: 2;

        @media screen and (min-width:800px) {
          grid-column: 4;
          grid-row: 1;
        }

        @include media-breakpoint-up(lg) {
          grid-column: 1;
          grid-row: 2;
        }

        @include media-breakpoint-up(xl) {
          grid-column: 4;
          grid-row: 1;
        }
      }

      .order-price {
        grid-column: 2;
        grid-row: 2;

        @media screen and (min-width:800px) {
          grid-column: 5;
          grid-row: 1;
        }

        @include media-breakpoint-up(lg) {
          grid-column: 2;
          grid-row: 2;
        }

        @include media-breakpoint-up(xl) {
          grid-column: 5;
          grid-row: 1;
        }
      }

      .order-actions {
        grid-column: 3;
        grid-row: 2;

        @media screen and (min-width:800px) {
          grid-column: 6;
          grid-row: 1;
        }

        @include media-breakpoint-up(lg) {
          grid-column: 3;
          grid-row: 2;
        }

        @include media-breakpoint-up(xl) {
          grid-column: 6;
          grid-row: 1;
        }

        .list-loader {
          @include loader;
          width: 48px;
          height: 48px;
          left: 203px;
          top: 0px;
        }
      }
    }

    .orders-navi {
      border-bottom: 1px solid $red;
      grid-template-rows: 48px;
      display: none;

      @media screen and (min-width:800px) {
        display: grid;
      }

      @include media-breakpoint-up(lg) {
        display: none;
      }

      @include media-breakpoint-up(xl) {
        display: grid;
      }

      .navi-element {
        font-size: 14px;
        line-height: 20px;
        padding-left: 16px;
        display: flex;
        align-items: center;
      }
    }

    .single-order-wrapper {
      &.open {
        background-color: $lightgray5;
      }
    }

    .single-order {
      grid-template-rows: 80px 92px;
      border-bottom: 1px solid $gray3;

      @media screen and (min-width:800px) {
        grid-template-rows: 82px;
        align-items: center;
      }

      @include media-breakpoint-up(lg) {
        grid-template-rows: 80px 92px;
        align-items: unset;
      }

      @include media-breakpoint-up(xl) {
        grid-template-rows: 82px;
        align-items: center;
      }

      .order-title {
        font-size: 14px;
        line-height: 20px;
        color: $gray3;
        font-family: $font-primary;
        text-transform: capitalize;

        @media screen and (min-width:800px) {
          display: none !important;
        }

        @include media-breakpoint-up (lg) {
          display: flex !important;
        }

        @include media-breakpoint-up(xl) {
          display: none !important;
        }
      }

      &:last-of-type {
        border-bottom: 1px solid transparent;
      }

      &:hover {
        background-color: $lightgray5;
      }

      .order-element {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        line-height: 18px;

        @media screen and (min-width:800px) {
          align-items: flex-start;
          padding-left: 16px;
        }

        @include media-breakpoint-up(lg) {
          padding-left: 0;
          align-items: unset;
        }

        @include media-breakpoint-up(xl) {
          align-items: flex-start;
          padding-left: 16px;
        }
      }

      .order-nr,
      .order-date,
      .order-dateupdated {
        .order-title {
          height: 52px;
          display: flex;
          align-items: center;
        }

        .order-value {
          height: 28px;

          @media screen and (min-width:800px) {
            height: auto;
          }

          @include media-breakpoint-up(lg) {
            height: 28px;
          }

          @include media-breakpoint-up(xl) {
            height: auto;
          }
        }
      }

      .order-status,
      .order-price {
        .order-title {
          height: 36px;
          display: flex;
          align-items: flex-end;

        }

        .order-value {
          height: 56px;
          display: flex;
          align-items: center;
        }
      }

      .order-nr,
      .order-price,
      .order-status {
        font-family: $font-bold;
      }

      .order-status {
        text-transform: uppercase;

        .order-value {
          display: flex;
          align-items: center;
        }

        .icon {
          margin-right: 8px;
        }

        .open {
          color: $black;
        }

        .finished {
          color: $green;
        }

        .shipping {
          color: $blue;
        }

        .special {
          color: $purple;
        }

        .locked {
          color: $red2;
        }
      }

      .order-actions {
        flex-direction: row;

        .more,
        .expand {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          &:hover {
            background-color: rgba($orange, 0.1);
          }
        }

        .expand-more {
          &.active {
            .icon-expand {
              transform: rotate(180deg);
            }
          }
        }

        @media screen and (min-width:800px) {
          justify-content: flex-start;
        }

        @include media-breakpoint-up(lg) {
          justify-self: unset;
        }

        @include media-breakpoint-up(xl) {
          justify-content: flex-start;
        }

        .more {
          position: relative;

          .more-options {
            position: absolute;
            background-color: $white;
            filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.25));
            z-index: 1;
            top: 40px;
            left: -132px;
            display: none;

            @media screen and(min-width:800px) {
              left: -163px;
            }

            @include media-breakpoint-up(lg) {
              left: -64px;
            }

            @include media-breakpoint-up(xl) {
              left: -107px;
            }

            @media screen and (min-width:1570px) {
              left: -77px;
            }

            @media screen and (min-width:1700px) {
              left: -7px;
            }

            a {
              color: $black;

              &:hover {
                color: $red;
                text-decoration: underline;
              }
            }

            .download {
              a {
                white-space: nowrap;
                padding: 13px 16px;
                position: relative;
                display: flex;
                align-items: center;

                &:before {
                  margin-right: 4px;
                  content: '';
                  display: inline-block;
                  width: 24px;
                  height: 24px;
                  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_446_6718)'%3E%3Cpath d='M19 9H15V3H9V9H5L12 16L19 9ZM11 11V5H13V11H14.17L12 13.17L9.83 11H11ZM5 18H19V20H5V18Z' fill='%230C0C0C'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_446_6718'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A") no-repeat center center / cover;
                }

                &:hover {
                  &:before {
                    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_446_6718)'%3E%3Cpath d='M19 9H15V3H9V9H5L12 16L19 9ZM11 11V5H13V11H14.17L12 13.17L9.83 11H11ZM5 18H19V20H5V18Z' fill='%23BA5F1B'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_446_6718'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A") no-repeat center center / cover;
                  }
                }
              }
            }

            .reorder {
              a {
                padding: 13px 16px;
                display: flex;
                align-items: center;

                &:before {
                  margin-right: 4px;
                  content: '';
                  display: inline-block;
                  width: 24px;
                  height: 24px;
                  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_446_6723)'%3E%3Cpath d='M7 7H17V10L21 6L17 2V5H5V11H7V7ZM17 17H7V14L3 18L7 22V19H19V13H17V17Z' fill='%230C0C0C'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_446_6723'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A") no-repeat center center / cover;
                }

                &:hover {
                  &:before {
                    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_446_6723)'%3E%3Cpath d='M7 7H17V10L21 6L17 2V5H5V11H7V7ZM17 17H7V14L3 18L7 22V19H19V13H17V17Z' fill='%23BA5F1B'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_446_6723'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A") no-repeat center center / cover;
                  }
                }
              }
            }
          }

          &.open-more {
            &.open {
              .more-options {
                display: block;
              }
            }
          }
        }
      }

      &.locked-order {
        color: $gray3;
      }
    }

    .single-order-details {
      padding-top: 32px;
      padding-right: 16px;
      padding-bottom: 32px;
      padding-left: 16px;
      display: none;

      &.open {
        display: block;
      }

      .details-header {
        display: flex;
        justify-content: space-between;
        padding-bottom: 32px;
        margin-bottom: 32px;
        border-bottom: 1px solid $gray3;
        flex-direction: column;

        .top {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          margin-bottom: 48px;

          @include media-breakpoint-up(md) {
            margin-bottom: 24px;
            flex-direction: row;
          }
        }

        .bot {
          .title {
            margin-bottom: 16px;
            font-family: $font-bold;
          }
        }

        .shipping-address {
          margin-bottom: 48px;

          @include media-breakpoint-up(md) {
            margin-bottom: 0;
          }

          .title {
            margin-bottom: 16px;
            font-family: $font-bold;
          }

          .address {
            line-height: 29px;
          }
        }

        .order-info {
          @include media-breakpoint-up(md) {
            width: 280px;
          }

          .element {
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;

            &:last-of-type {
              margin-bottom: 0;
            }

            .title {
              margin-bottom: 0;
              font-family: $font-bold;
            }
          }
        }
      }

      .details-products {
        .details-products-header {
          display: none;

          @include media-breakpoint-up(md) {
            display: grid;
            //grid-template-columns: 7% 15% 63% 15%;
            grid-template-columns: 5% 42% 8% 15% 15% 15%;
            grid-template-rows: 48px;
            border-bottom: 1px solid $red;
          }

          .header-item {
            display: flex;
            align-items: center;
            padding-left: 16px;

            &.header-price,
            &.header-discount,
            &.header-total {
              justify-content: flex-end;
            }
          }
        }

        .details-product-wrapper {
          .details-product-grid {
            margin-bottom: 32px;

            .details-product {
              display: grid;
              grid-template-columns: 50% 50%;
              grid-template-rows: minmax(84px, auto);
              border-bottom: 1px solid $gray3;

              @include media-breakpoint-up(md) {
                //grid-template-columns: 7% 15% 63% 15%;
                grid-template-columns: 5% 42% 8% 15% 15% 15%;
              }


              .details-product-element {
                display: flex;
                align-items: flex-start;
                padding-top: 16px;
                padding-bottom: 16px;

                @include media-breakpoint-up(md) {
                  padding-left: 16px;
                  justify-content: center;
                }

                .product-title {
                  font-family: $font-primary;
                  font-size: 14px;
                  line-height: 20px;
                  margin-bottom: 8px;
                  color: $gray3;
                }
              }

              .details-nr,
              .details-quantity,
              .product-id {
                font-size: 14px;
              }

              .product-id,
              .details-price {
                font-family: $font-bold;
              }

              .details-price {
                justify-content: flex-end;
                flex-direction: column;
              }

              .details-product-name {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;

                grid-column: 1 / span 2;
                grid-row: 2;

                @include media-breakpoint-up(md) {
                  grid-column: 2;
                  grid-row: 1;
                }
              }

              .details-nr {
                grid-column: 1;
                grid-row: 1;
                flex-direction: column;
              }

              .details-quantity {
                grid-column: 2;
                grid-row: 1;
                flex-direction: column;
                align-items: flex-end;

                @include media-breakpoint-up(md) {
                  grid-column: 3;
                }

                .red {
                  margin-left: 4px;
                  color: $red;
                  font-family: $font-bold;
                }

                .green {
                  margin-left: 4px;
                  color: $green;
                  font-family: $font-bold;
                }
              }

              .details-price {
                grid-column: 1;
                grid-row: 3;
                justify-content: flex-start;

                @include media-breakpoint-up(md) {
                  grid-column: 4;
                  grid-row: 1;
                  justify-content: center;
                  align-items: flex-end;
                }
              }

              .details-rebate {
                display: flex;
                flex-direction: column;
                grid-column: 2;
                grid-row: 4;

                @include media-breakpoint-up(md) {
                  grid-column: 5;
                  grid-row: 1;
                }

                .rabate-row {
                  .rebate {
                    min-width: 40px;
                    display: flex;
                    justify-content: end;
                  }
                }
              }

              .details-total {
                grid-column: 1;
                grid-row: 4;
                flex-direction: column;

                @include media-breakpoint-up(md) {
                  grid-column: 6;
                  grid-row: 1;
                  align-items: center;
                  flex-direction: unset;
                }
              }
            }

            .details-title {
              color: $gray3;
              font-size: 14px;
              font-family: $font-primary;
            }
          }

          .details-product-additiona-info {
            padding-bottom: 32px;
            border-bottom: 1px solid $gray3;

            .additiona-info-element {
              margin-bottom: 24px;

              @include media-breakpoint-up(md) {
                display: grid;
                grid-template-columns: 20% 80%;
                margin-bottom: 24px;
              }

              &:last-of-type {
                margin-bottom: 0;
              }

              .title {
                margin-bottom: 8px;
                font-family: $font-bold;

                @include media-breakpoint-up(md) {
                  margin-bottom: 0;
                }
              }

              .content {
                @include media-breakpoint-up(md) {
                  padding-left: 16px;
                }

                p {
                  &:first-of-type {
                    margin-top: 0;
                  }
                }
              }
            }
          }
        }
      }
    }

    .frequently-purchased-products {
      .title {
        h2 {
          font-size: 30px;
          line-height: 34px;
          margin-bottom: 24px;

          @include media-breakpoint-up(lg) {
            font-size: 50px;
            line-height: 57px;
          }
        }

        a {
          font-family: $font-bold;
          color: $black;
          padding-right: 30px;
          position: relative;
          white-space: nowrap;

          &:after {
            position: absolute;
            right: 5px;
            top: -1px;
            content: '';
            display: inline-block;
            width: 24px;
            height: 24px;
            transition: all 0.3s ease;
            background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_446_7032)'%3E%3Cpath d='M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z' fill='%2302020A'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_446_7032'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A") no-repeat center center / cover;
          }

          &:hover {
            &:after {
              right: 0;
            }
          }
        }
      }

      .frequently-purchased-products-wrapper {
        .frequently-purchased-product {
          display: grid;
          grid-template-columns: 100%;
          grid-template-rows: auto 30px 24px 72px 72px;
          padding-top: 32px;
          padding-bottom: 32px;
          border-bottom: 1px solid $gray3;

          @include media-breakpoint-up(md) {
            grid-template-columns: 75% 25%;
            grid-template-rows: auto auto;
            gap: 10px 0;
          }

          &:last-of-type {
            border-bottom: none;
          }

          .product-name {
            grid-column: 1;
            grid-row: 1;
            margin-bottom: 16px;

            @include media-breakpoint-up(md) {
              margin-bottom: 0;
            }

            .product-info {
              color: $gray3;
              font-size: 14px;
              line-height: 20px;
              margin-bottom: 8px;

              .separator {
                margin-left: 8px;
                margin-right: 8px;
              }
            }

            h3 {
              font-size: 21px;
              line-height: 29px;
              color: $black;
            }

            &:hover {
              h3 {
                text-decoration: underline;
              }
            }
          }

          .product-stock {
            grid-column: 1;
            grid-row: 3;
            margin-top: 10px;

            @include media-breakpoint-up(md) {
              grid-column: 1;
              grid-row: 3;
              margin-top: 0;
            }

            font-size: 14px;
            line-height: 20px;
            display: flex;
            align-items: center;

            &.yes {
              color: $green;
            }

            &.no {
              color: $red2;
            }
          }

          .product-price {
            grid-column: 1;
            grid-row: 2;

            display: flex;
            flex-direction: row;
            align-items: flex-end;

            @include media-breakpoint-up(md) {
              grid-column: 2;
              grid-row: 1;
              flex-direction: column;
            }

            .base-price {
              color: $gray3;
              line-height: 22px;
              text-decoration: line-through;
              order: 2;
              margin-right: 16px;

              @include media-breakpoint-up(md) {
                order: 1;
                margin-right: 0;
              }
            }

            .price {
              color: $orange;
              font-family: $font-bold;
              font-size: 21px;
              line-height: 24px;
              order: 1;
              margin-right: 16px;

              @include media-breakpoint-up(md) {
                margin-bottom: 8px;
                margin-right: 0;
                order: 2;
              }
            }

            .full-price {
              font-size: 12px;
              line-height: 16px;
              order: 3;
            }
          }

          .product-quantity {
            grid-column: 1;
            grid-row: 4;

            align-self: end;
            display: flex;
            align-items: center;

            @include media-breakpoint-up(md) {
              grid-column: 1;
              grid-row: 3;
            }

            .quantity {
              margin-right: 8px;

              input {
                width: 88px;
                height: 48px;
                text-align: center;
                border: 1px solid $black;
                border-radius: 0;
              }
            }
          }

          .add-button {
            grid-column: 1;
            grid-row: 5;
            display: flex;
            align-items: flex-end;

            @include media-breakpoint-up(md) {
              grid-column: 2;
              grid-row: 3;
              place-self: end;
            }

            a {
              white-space: nowrap;
            }
          }
        }
      }
    }

    .account-orders {
      position: relative;

      .account-orders-dynamic {
        min-height: 150px;
      }

      .orders {
        margin-bottom: 0;
      }

      .account-orders-search-wrapper {
        padding-left: 16px;
        padding-right: 16px;
        margin-bottom: 48px;
      }

      .account-orders-search {
        background-color: $lightgray5;
        padding-top: 24px;
        padding-bottom: 24px;
        padding-left: 8px;
        padding-right: 8px;

        h2 {
          font-size: 28px;
          line-height: 32px;
          margin-bottom: 16px;
        }

        .search-input-group {
          form {
            margin-bottom: 0;
            display: grid;
            grid-template-columns: 100%;
            grid-template-rows: 84px;
            justify-content: space-between;

            @include media-breakpoint-up(md) {
              grid-template-columns: 25% 25% 25% 17%;
              grid-template-rows: 72px;
            }

            .form-group {
              margin-bottom: 0;
              height: 100%;
              width: 100%;
              margin-bottom: 12px;
              display: flex;
              flex-direction: column;

              @include media-breakpoint-up(md) {
                margin-bottom: 0;
              }

              input,
              select {
                width: 100%;
                height: 48px;
                padding-top: 12px;
                padding-right: 16px;
                padding-bottom: 12px;
                padding-left: 16px;
              }

              label {
                color: $gray4;
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 4px;
              }
            }

            .search-button {
              align-self: end;
              margin-bottom: 1px;
              max-width: 314px;

              button {
                width: 100%;
                height: 48px;

                &:hover {
                  background-color: $red;
                  border: 1px solid $red;
                }
              }
            }
          }
        }
      }

      .order-list-pagination {
        padding-left: 16px;
      }
    }

    .account-documents {

      .account-documents-search,
      .conformity-top {
        background-color: $lightgray5;
        padding-top: 24px;
        padding-bottom: 24px;
        padding-left: 8px;
        padding-right: 8px;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 48px;

        h2 {
          font-size: 28px;
          line-height: 32px;
          margin-bottom: 16px;
        }
      }

      .search-input-group {
        form {
          margin-bottom: 0;
          display: grid;
          grid-template-columns: 100%;
          grid-template-rows: 84px;
          justify-content: space-between;

          @include media-breakpoint-up(md) {
            grid-template-columns: 18% 18% 18% 18% 18%;
            grid-template-rows: 72px;
          }

          .form-group {
            margin-bottom: 0;
            height: 100%;
            width: 100%;
            margin-bottom: 12px;
            display: flex;
            flex-direction: column;

            @include media-breakpoint-up(md) {
              margin-bottom: 0;
            }

            input,
            select {
              width: 100%;
              height: 48px;
              padding-top: 12px;
              padding-right: 16px;
              padding-bottom: 12px;
              padding-left: 16px;
            }

            label {
              color: $gray4;
              font-size: 14px;
              line-height: 20px;
              margin-bottom: 4px;
            }
          }

          .search-button {
            align-self: end;
            margin-bottom: 1px;
            max-width: 314px;

            button {
              width: 100%;
              height: 48px;

              &:hover {
                background-color: $red;
                border: 1px solid $red;
              }
            }
          }
        }
      }
    }


    .account-discounts {
      .cat-disc {
        margin-bottom: 48px;

        h2 {
          margin-bottom: 32px;
        }
      }

      .no-rabat {
        margin-top: 24px;
      }

      .discounts {
        .discount-grid {
          display: grid;
          grid-template-columns: 50% 50%;
          grid-template-rows: minmax(48px, auto);

          @include media-breakpoint-up(md) {
            grid-template-columns: 20% 20% 20% 20% 20%;
            grid-template-rows: minmax(84px, auto);
          }

          &.small {
            @include media-breakpoint-up(md) {
              grid-template-columns: 25% 25% 25% 25%;
              grid-template-rows: minmax(84px, auto);
            }
          }

          .element {
            padding-left: 16px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-top: 16px;
            padding-bottom: 16px;

            @include media-breakpoint-up(md) {
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;
            }

            a {
              color: $orange;
              text-decoration: underline;

              &:hover {
                text-decoration: none;
              }
            }
          }

          .label {
            grid-column: 1;
            grid-row: 1 / 2;

            @include media-breakpoint-up(md) {
              grid-column: 1;
              grid-row: 1;
            }
          }

          .code {
            grid-column: 1;
            grid-row: 2;

            @include media-breakpoint-up(md) {
              grid-column: 2;
              grid-row: 1;
            }
          }

          .rabate-1 {
            grid-column: 2;
            grid-row: 2;

            @include media-breakpoint-up(md) {
              grid-column: 3;
              grid-row: 1;
            }
          }

          .rebate-2 {
            grid-column: 1;
            grid-row: 3;

            @include media-breakpoint-up(md) {
              grid-column: 4;
              grid-row: 1;
            }
          }

          .price {
            grid-column: 2;
            grid-row: 3;

            @include media-breakpoint-up(md) {
              grid-column: 5;
              grid-row: 1;
            }
          }
        }

        .discounts-navi {
          grid-template-rows: 48px;
          border-bottom: 1px solid $red;
          display: none;

          @include media-breakpoint-up(md) {
            display: grid;
          }

          .element {
            font-size: 14px;
            line-height: 20px;
          }
        }

        .single-discount {
          border-bottom: 1px solid $gray3;
          padding-top: 16px;

          @include media-breakpoint-up(md) {
            padding-top: 0;
          }

          &:last-of-type {
            border-bottom: 0;
          }

          .label {
            font-family: $font-bold;
          }

          .rabate-1,
          .rabate-2 {
            color: $orange;
            font-family: $font-bold;
            justify-content: space-between;
          }

          .discount-title {
            color: $gray3;
            font-size: 14px;
            line-height: 20px;
            font-family: $font-primary;

            @include media-breakpoint-up(md) {
              display: none;
            }
          }
        }
      }
    }

    .obligations {
      .obligations-grid {
        display: grid;
        grid-template-columns: 33.333% 33.333% 33.333%;

        @include media-breakpoint-up(md) {
          grid-template-columns: 12% 12% 25% 12% 12% 12% 15%;
        }


        .element {
          padding-left: 16px;
          font-size: 14px;
          line-height: 20px;
          display: flex;
          align-items: center;
        }
      }

      .obligations-navi {
        grid-template-rows: 48px;
        border-bottom: 1px solid $red;
        display: none;

        @include media-breakpoint-up(md) {
          display: grid;
        }

      }

      .single-obligation {
        grid-template-rows: 62px 72px 72px 72px;
        border-bottom: 1px solid $gray3;
        padding-bottom: 16px;
        padding-top: 16px;

        @include media-breakpoint-up(md) {
          padding-bottom: 0;
          padding-top: 0;
        }

        @include media-breakpoint-up(md) {
          grid-template-rows: 84px;
        }

        &:last-of-type {
          border-bottom: 0;
        }

        .element {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-end;

          @include media-breakpoint-up(md) {
            justify-content: center;
          }
        }

        .obligation-title {
          color: $gray3;
          font-family: $font-primary;
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 9px;

          @include media-breakpoint-up(md) {
            display: none;
          }
        }

        .receipt-nr {
          font-size: 16px;
          line-height: 18px;
          font-family: $font-bold;

          grid-column: 1;
          grid-row: 1;
        }

        .date {
          grid-column: 2;
          grid-row: 1;
        }

        .name {
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          grid-column: 1 / span 2;
          grid-row: 2;

          @include media-breakpoint-up(md) {
            grid-column: 3;
            grid-row: 1;
          }
        }

        .re-amount {
          grid-column: 1;
          grid-row: 3;

          @include media-breakpoint-up(md) {
            grid-column: 4;
            grid-row: 1;
          }
        }

        .open {
          grid-column: 2;
          grid-row: 3;

          @include media-breakpoint-up(md) {
            grid-column: 5;
            grid-row: 1;
          }
        }

        .due-obligation {
          grid-column: 1;
          grid-row: 4;

          @include media-breakpoint-up(md) {
            grid-column: 6;
            grid-row: 1;
          }
        }

        .element-options {
          grid-column: 3;
          grid-row: 1;
          flex-direction: row;
          justify-content: flex-start;

          @include media-breakpoint-up(md) {
            grid-column: 7;
            grid-row: 1;
            align-items: center;
          }

          .element-option {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: rgba($orange, 0);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            a {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            &:hover {
              background-color: rgba($orange, 0.1);
            }
          }

          .download {
            .download-icon {
              content: '';
              display: inline-block;
              width: 24px;
              height: 24px;
              background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_446_6718)'%3E%3Cpath d='M19 9H15V3H9V9H5L12 16L19 9ZM11 11V5H13V11H14.17L12 13.17L9.83 11H11ZM5 18H19V20H5V18Z' fill='%23e87722'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_446_6718'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A") no-repeat center center / cover;

            }
          }
        }
      }
    }

    .my-profile {
      margin-top: 48px;

      @include media-breakpoint-up(lg) {
        margin-top: 0;
      }

      h2 {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 32px;
      }

      .account-info {
        padding-top: 24px;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 62px;

        .info-wrapper {
          .element {
            margin-bottom: 16px;

            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }

      .change-password {

        padding: 24px;

        form {
          .form-group {
            max-width: 314px;
            width: 100%;

            input {
              border: 1px solid $black;
              border-radius: 0;
              height: 48px;
              padding-top: 12px;
              padding-right: 16px;
              padding-bottom: 12px;
              padding-left: 16px;
              color: $gray3;
            }
          }
        }
      }
    }

    .account-company {
      .company-info {
        margin-bottom: 64px;

        h2 {
          font-size: 21px;
          line-height: 29px;
          margin-bottom: 32px;
        }

        .info-wrapper {
          .element {
            margin-bottom: 18px;

            &:last-of-type {
              margin-bottom: 0;
            }
          }

          .e-mail {
            .title {
              margin-right: 6px;
            }
          }
        }
      }

      .delivery-addresses {
        .title-wrapper {
          display: flex;
          justify-content: space-between;

          margin-bottom: 32px;
          flex-direction: column;

          @include media-breakpoint-up(md) {
            flex-direction: row;
            align-items: center;
          }

          h2 {
            font-size: 21px;
            line-height: 29px;
            margin-bottom: 24px;

            @include media-breakpoint-up(md) {
              margin-bottom: 0;
            }
          }
        }

        .open-btn {
          position: relative;
          padding-right: 44px;

          .plus {
            position: absolute;
            transform: rotate(0);
            transition: all 0.3s ease;
            margin-left: 4px;
          }

          &.open {
            .plus {
              transform: rotate(90deg);

            }
          }
        }

        .add-new-address-wrapper {
          display: none;
        }

        select {
          border: 1px solid $black;
          border-radius: 0;
          height: 48px;
          padding-top: 12px;
          padding-right: 16px;
          padding-bottom: 12px;
          padding-left: 7px;
          max-width: 314px;
          color: $gray3;
          width: 100%;
          font-size: 16px;
          cursor: pointer;

          option {
            font-size: 16px;
          }
        }
      }

      .adresses-wrapper {
        .addresses-grid {
          display: grid;

          grid-template-columns: 80% 20%;

          @include media-breakpoint-up(md) {
            grid-template-columns: 24% 24% 14% 14% 14% 10%;
          }

          @include media-breakpoint-up(lg) {
            grid-template-columns: 80% 20%;
          }

          @include media-breakpoint-up(xl) {
            grid-template-columns: 24% 24% 14% 14% 14% 10%;
          }


          .element {
            @include media-breakpoint-up(md) {
              padding-left: 16px;
            }

            @include media-breakpoint-up(lg) {
              padding-left: 0;
            }

            @include media-breakpoint-up(xl) {
              padding-left: 16px;
            }
          }
        }

        .header {
          padding-bottom: 4px;
          border-bottom: 1px solid $red;
          font-size: 14px;
          line-height: 22px;
          grid-template-rows: 24px;
          display: none;

          @include media-breakpoint-up(md) {
            display: grid;
          }

          @include media-breakpoint-up(lg) {
            display: none;
          }

          @include media-breakpoint-up(xl) {
            display: grid;
          }
        }

        .content {
          grid-template-rows: 64px 29px 29px 29px 29px;
          border-bottom: 1px solid $gray3;
          padding-bottom: 32px;
          padding-top: 16px;

          @include media-breakpoint-up(md) {
            grid-template-rows: 84px;
            padding-bottom: 0;
            padding-top: 0;
          }

          @include media-breakpoint-up(lg) {
            grid-template-rows: 51px 29px 29px 29px 29px;
            padding-bottom: 32px;
            padding-top: 16px;
          }

          @include media-breakpoint-up(xl) {
            grid-template-rows: 84px;
            padding-bottom: 0;
            padding-top: 0;
          }

          .element {
            display: flex;
            align-items: center;
            font-size: 16px;
            line-height: 22px;
          }

          .company-name {
            font-family: $font-bold;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;

            grid-column: 1;
            grid-row: 1;

            .company-info {
              color: $gray3;
              font-size: 14px;
              line-height: 18px;
              margin-bottom: 4px;
              font-family: $font-primary;
            }
          }

          .company-street {
            grid-column: 1;
            grid-row: 2;

            @include media-breakpoint-up(md) {
              grid-column: 2;
              grid-row: 1;
            }

            @include media-breakpoint-up(lg) {
              grid-column: 1;
              grid-row: 2;
            }

            @include media-breakpoint-up(xl) {
              grid-column: 2;
              grid-row: 1;
            }
          }

          .company-zip {
            grid-column: 1;
            grid-row: 3;

            @include media-breakpoint-up(md) {
              grid-column: 3;
              grid-row: 1;
            }

            @include media-breakpoint-up(lg) {
              grid-column: 1;
              grid-row: 3;
            }

            @include media-breakpoint-up(xl) {
              grid-column: 3;
              grid-row: 1;
            }
          }

          .company-city {
            grid-column: 1;
            grid-row: 4;

            @include media-breakpoint-up(md) {
              grid-column: 4;
              grid-row: 1;
            }

            @include media-breakpoint-up(lg) {
              grid-column: 1;
              grid-row: 4;
            }

            @include media-breakpoint-up(xl) {
              grid-column: 4;
              grid-row: 1;
            }
          }

          .company-country {
            grid-column: 1;
            grid-row: 5;

            @include media-breakpoint-up(md) {
              grid-column: 5;
              grid-row: 1;
            }

            @include media-breakpoint-up(lg) {
              grid-column: 1;
              grid-row: 5;
            }

            @include media-breakpoint-up(xl) {
              grid-column: 5;
              grid-row: 1;
            }
          }

          .company-more {
            grid-column: 2;
            grid-row: 1;
            justify-content: flex-end;
            position: relative;

            @include media-breakpoint-up(md) {
              grid-column: 6;
              grid-row: 1;
            }

            @include media-breakpoint-up(lg) {
              grid-column: 2;
              grid-row: 1;
            }

            @include media-breakpoint-up(xl) {
              grid-column: 6;
              grid-row: 1;
            }

            .more-wrapper {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              &:hover {
                background-color: rgba($orange, 0.1);
              }

              &.open-more {
                &.open {
                  .more-options {
                    display: block;
                  }
                }
              }
            }

            .more-options {
              position: absolute;
              background-color: $white;
              filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.25));
              z-index: 1;
              top: 40px;
              left: -132px;
              display: none;

              @media screen and(min-width:800px) {
                left: -163px;
              }

              @include media-breakpoint-up(lg) {
                left: -64px;
              }

              @include media-breakpoint-up(xl) {
                left: -107px;
              }

              @media screen and (min-width:1570px) {
                left: -77px;
              }

              @media screen and (min-width:1700px) {
                left: -7px;
              }

              a {
                color: $black;

                &:hover {
                  color: $red;
                  text-decoration: underline;
                }
              }

              .edit {
                a {
                  white-space: nowrap;
                  padding: 13px 16px;
                  position: relative;
                  display: flex;
                  align-items: center;

                  &:before {
                    margin-right: 4px;
                    content: '';
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1724_22709)'%3E%3Cpath d='M3 17.25V21H6.75L17.81 9.94L14.06 6.19L3 17.25ZM5.92 19H5V18.08L14.06 9.02L14.98 9.94L5.92 19ZM20.71 5.63L18.37 3.29C18.17 3.09 17.92 3 17.66 3C17.4 3 17.15 3.1 16.96 3.29L15.13 5.12L18.88 8.87L20.71 7.04C21.1 6.65 21.1 6.02 20.71 5.63Z' fill='%2302020A'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1724_22709'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A") no-repeat center center / cover;
                  }

                  &:hover {
                    &:before {
                      background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1724_22709)'%3E%3Cpath d='M3 17.25V21H6.75L17.81 9.94L14.06 6.19L3 17.25ZM5.92 19H5V18.08L14.06 9.02L14.98 9.94L5.92 19ZM20.71 5.63L18.37 3.29C18.17 3.09 17.92 3 17.66 3C17.4 3 17.15 3.1 16.96 3.29L15.13 5.12L18.88 8.87L20.71 7.04C21.1 6.65 21.1 6.02 20.71 5.63Z' fill='%23BA5F1B'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1724_22709'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A") no-repeat center center / cover;
                    }
                  }
                }
              }

              .remove {
                a {
                  padding: 13px 16px;
                  display: flex;
                  align-items: center;

                  &:before {
                    margin-right: 4px;
                    content: '';
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1724_21119)'%3E%3Cpath d='M16 9V19H8V9H16ZM14.5 3H9.5L8.5 4H5V6H19V4H15.5L14.5 3ZM18 7H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7Z' fill='%2302020A'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1724_21119'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A") no-repeat center center / cover;
                  }

                  &:hover {
                    &:before {
                      background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1724_21119)'%3E%3Cpath d='M16 9V19H8V9H16ZM14.5 3H9.5L8.5 4H5V6H19V4H15.5L14.5 3ZM18 7H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7Z' fill='%23BA5F1B'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1724_21119'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A") no-repeat center center / cover;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .documents-section {
      height: 100%;

      h2 {
        margin-bottom: 32px;
      }

      .documents-grid {
        display: grid;
        grid-template-columns: 50% 50%;

        @include media-breakpoint-up(md) {
          grid-template-columns: 12% 12% 13% 12% 13% 14% 14% 10%;
        }


        .element {
          display: flex;
          //align-items: center;
          flex-direction: column;

          @include media-breakpoint-up(md) {
            padding-left: 16px;
            padding-top: 16px;
            padding-bottom: 16px;
            //align-items: center;
            justify-content: center;
          }

          .documents-title {
            color: $gray3;
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 8px;

            @include media-breakpoint-up(md) {
              display: none;
            }
          }

          &.more-documents {
            flex-direction: row;
            align-items: center;

            .expand {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              &:hover {
                background-color: rgba($orange, 0.1);
              }
            }
          }
        }

        &.obligations {
          @include media-breakpoint-up(md) {
            grid-template-columns: 13% 14% 16% 22% 15% 14% 6%;
          }
        }

        &.conformity,
        &.contract,
        &.yearly {
          @include media-breakpoint-up(md) {
            grid-template-columns: 15% 15% 64% 6%;
          }
        }

        .documents-list-pagination {
          padding-left: 16px;
        }
      }

      .documents-navi {
        grid-template-rows: 32px;
        border-bottom: 1px solid $red !important;
        display: none;

        @include media-breakpoint-up(md) {
          display: grid;
        }
      }

      .single-document {
        @include media-breakpoint-up(md) {
          border-bottom: 1px solid $gray3;
        }
      }

      .documents-list {
        //grid-template-rows: 66px 66px 66px;
        grid-template-rows: minmax(66px, auto);
        padding-bottom: 25px;
        padding-top: 16px;
        border-bottom: 1px solid $gray3;

        @include media-breakpoint-up(md) {
          padding-top: 0;
          padding-bottom: 0;
          //grid-template-rows: 66px;
          grid-template-rows: minmax(66px, auto);
        }

        &:last-of-type {
          border-bottom: none;
        }
      }

      .document-name {
        grid-column: 1;
        grid-row: 1;
      }

      .document-number {
        grid-column: 2;
        grid-row: 1;
      }

      .document-date {
        grid-column: 1;
        grid-row: 2;
        padding-top: 16px;
        padding-bottom: 16px;

        @include media-breakpoint-up(md) {
          grid-column: 3;
          grid-row: 1;
          padding-top: 0;
          padding-bottom: 0;
        }
      }

      .document-dateupdated {
        grid-column: 2;
        grid-row: 2;
        padding-top: 16px;
        padding-bottom: 16px;

        @include media-breakpoint-up(md) {
          grid-column: 4;
          grid-row: 1;
          padding-top: 0;
          padding-bottom: 0;
        }
      }

      .document-subject {
        grid-column: 1;
        grid-row: 4;
        padding-top: 16px;
        padding-bottom: 16px;

        @include media-breakpoint-up(md) {
          grid-column: 5;
          grid-row: 1;
          padding-top: 0;
          padding-bottom: 0;
        }
      }

      .document-net-amount {
        grid-column: 1;
        grid-row: 3;


        @include media-breakpoint-up(md) {
          grid-column: 6;
          grid-row: 1;
          align-items: flex-end;
        }
      }

      .document-gross-amount {
        grid-column: 2;
        grid-row: 3;


        @include media-breakpoint-up(md) {
          grid-column: 7;
          grid-row: 1;
          align-items: flex-end;
        }
      }

      .document-more {
        grid-column: 2;
        grid-row: 4;
        justify-content: center;

        @include media-breakpoint-up(md) {
          grid-column: 8;
          grid-row: 1;
        }

        .list-loader {
          @include loader;
          width: 48px;
          height: 48px;
          left: 203px;
          top: 1px;
        }

        .open-more {
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          cursor: pointer;
          position: relative;

          &:hover {
            background-color: rgba($orange, 0.1);
          }

          &.open {
            .more-options {
              display: block;
            }
          }

          .more-options {
            position: absolute;
            background-color: $white;
            filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.25));
            z-index: 1;
            top: 40px;
            left: -132px;
            display: none;

            @media screen and(min-width:800px) {
              left: -163px;
            }

            // @include media-breakpoint-up(lg) {
            //   left: -64px;
            // }

            // @include media-breakpoint-up(xl) {
            //   left: -107px;
            // }

            // @media screen and (min-width:1570px) {
            //   left: -77px;
            // }

            // @media screen and (min-width:1700px) {
            //   left: -7px;
            // }

            a,
            span {
              color: $black;

              &:hover {
                color: $red;
                text-decoration: underline;
              }
            }

            .download {
              a {
                white-space: nowrap;
                padding: 13px 16px;
                position: relative;
                display: flex;
                align-items: center;

                &:before {
                  margin-right: 4px;
                  content: '';
                  display: inline-block;
                  width: 24px;
                  height: 24px;
                  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_446_6718)'%3E%3Cpath d='M19 9H15V3H9V9H5L12 16L19 9ZM11 11V5H13V11H14.17L12 13.17L9.83 11H11ZM5 18H19V20H5V18Z' fill='%230C0C0C'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_446_6718'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A") no-repeat center center / cover;
                }

                &:hover {
                  &:before {
                    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_446_6718)'%3E%3Cpath d='M19 9H15V3H9V9H5L12 16L19 9ZM11 11V5H13V11H14.17L12 13.17L9.83 11H11ZM5 18H19V20H5V18Z' fill='%23BA5F1B'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_446_6718'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A") no-repeat center center / cover;
                  }
                }
              }
            }

            .makeInquiry {
              span {
                white-space: nowrap;
                padding: 13px 16px;
                position: relative;
                display: flex;
                align-items: center;

                &:before {
                  content: '';
                  margin-right: 4px;
                  display: inline-block;
                  width: 24px;
                  min-width: 24px;
                  height: 24px;
                  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_37_14134)'%3E%3Cpath d='M3 4C3 2.9 3.89 2 5 2H14V4H5V18H9.83L10.41 18.58L12 20.17L13.58 18.59L14.17 18H19V10H21V18C21 19.1 20.1 20 19 20H15L12 23L9 20H5C3.89 20 3 19.1 3 18V4Z' fill='%2302020A'/%3E%3Cpath d='M24 5V3H21V0H19V3H16V5H19V8H21V5H24Z' fill='%2302020A'/%3E%3Cpath d='M13 15H11V17H13V15Z' fill='%2302020A'/%3E%3Cpath d='M14 9C14 7.9 13.1 7 12 7C10.9 7 10 7.9 10 9H8C8 6.79 9.79 5 12 5C14.21 5 16 6.79 16 9C16 11.5 13 11.75 13 14H11C11 10.75 14 11 14 9Z' fill='%2302020A'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_37_14134'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A") no-repeat center center/cover;
                }
              }

              &:hover {
                span {
                  &:before {
                    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_37_14134)'%3E%3Cpath d='M3 4C3 2.9 3.89 2 5 2H14V4H5V18H9.83L10.41 18.58L12 20.17L13.58 18.59L14.17 18H19V10H21V18C21 19.1 20.1 20 19 20H15L12 23L9 20H5C3.89 20 3 19.1 3 18V4Z' fill='%23BA5F1B'/%3E%3Cpath d='M24 5V3H21V0H19V3H16V5H19V8H21V5H24Z' fill='%23BA5F1B'/%3E%3Cpath d='M13 15H11V17H13V15Z' fill='%23BA5F1B'/%3E%3Cpath d='M14 9C14 7.9 13.1 7 12 7C10.9 7 10 7.9 10 9H8C8 6.79 9.79 5 12 5C14.21 5 16 6.79 16 9C16 11.5 13 11.75 13 14H11C11 10.75 14 11 14 9Z' fill='%23BA5F1B'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_37_14134'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A") no-repeat center center/cover;
                  }
                }
              }
            }

            .confirmOffer {
              span {
                white-space: nowrap;
                padding: 13px 16px;
                position: relative;
                display: flex;
                align-items: center;

                &::before {
                  content: '';
                  display: inline-block;
                  width: 22px;
                  height: 22px;
                  margin-right: 4px;
                  background: url("data:image/svg+xml;charset=UTF-8,%3csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M7.00065 0.333008C3.32065 0.333008 0.333984 3.31967 0.333984 6.99967C0.333984 10.6797 3.32065 13.6663 7.00065 13.6663C10.6807 13.6663 13.6673 10.6797 13.6673 6.99967C13.6673 3.31967 10.6807 0.333008 7.00065 0.333008ZM7.00065 12.333C4.06065 12.333 1.66732 9.93967 1.66732 6.99967C1.66732 4.05967 4.06065 1.66634 7.00065 1.66634C9.94065 1.66634 12.334 4.05967 12.334 6.99967C12.334 9.93967 9.94065 12.333 7.00065 12.333ZM10.0607 4.05301L5.66732 8.44634L3.94065 6.72634L3.00065 7.66634L5.66732 10.333L11.0007 4.99967L10.0607 4.05301Z' fill='%2302020A'/%3e%3c/svg%3e") no-repeat center center/cover;
                }
              }

              &:hover {
                span {
                  &:before {
                    background: url("data:image/svg+xml;charset=UTF-8,%3csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M7.00065 0.333008C3.32065 0.333008 0.333984 3.31967 0.333984 6.99967C0.333984 10.6797 3.32065 13.6663 7.00065 13.6663C10.6807 13.6663 13.6673 10.6797 13.6673 6.99967C13.6673 3.31967 10.6807 0.333008 7.00065 0.333008ZM7.00065 12.333C4.06065 12.333 1.66732 9.93967 1.66732 6.99967C1.66732 4.05967 4.06065 1.66634 7.00065 1.66634C9.94065 1.66634 12.334 4.05967 12.334 6.99967C12.334 9.93967 9.94065 12.333 7.00065 12.333ZM10.0607 4.05301L5.66732 8.44634L3.94065 6.72634L3.00065 7.66634L5.66732 10.333L11.0007 4.99967L10.0607 4.05301Z' fill='%23BA5F1B'/%3e%3c/svg%3e") no-repeat center center/cover;
                  }
                }
              }
            }
          }
        }
      }

      .obligations {
        .document-gross-amount {
          grid-column: 1;
          grid-row: 3;

          @include media-breakpoint-up(md) {
            grid-column: 5;
            grid-row: 1;
            align-items: flex-end;
          }
        }

        .document-more {
          @include media-breakpoint-up(md) {
            grid-column: 7;
            grid-row: 1;
          }
        }

        .document-subject {
          @include media-breakpoint-up(md) {
            grid-column: 4;
          }
        }
      }

      .document-type-selector {
        margin-bottom: 48px;

        select {
          border: 1px solid $black;
          background: $white;
          border-radius: 0;
          height: 48px;
          padding-top: 12px;
          padding-right: 16px;
          padding-bottom: 12px;
          padding-left: 7px;
          max-width: 314px;
          color: $gray3;
          width: 100%;
          font-size: 16px;
          cursor: pointer;

          option {
            font-size: 16px;
          }
        }

        .switch {
          display: flex;
          margin-top: 8px;
          margin-bottom: 27px;

          label.switch {
            position: relative;
            display: flex;
            margin-bottom: 0;

            a {
              text-decoration: underline;
            }

            input {
              -ms-appearance: none;
              -moz-appearance: none;
              -webkit-appearance: none;
              appearance: none;
              display: none;

              &:checked+.slider {
                background-color: $green;
              }

              &:checked+.check-square {
                background-color: $red;
                border: 2px solid $red;
                display: flex;
                justify-content: center;
                align-items: center;

                &:before {
                  background: url("data:image/svg+xml,%3Csvg width='18' height='14' viewBox='0 0 18 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.00039 11.2001L1.80039 7.0001L0.400391 8.4001L6.00039 14.0001L18.0004 2.0001L16.6004 0.600098L6.00039 11.2001Z' fill='%23ffffff'/%3E%3C/svg%3E%0A") no-repeat center center / cover;
                  display: inline-block;
                  width: 15px;
                  height: 12px;
                  content: '';
                }
              }

              &:focus {
                outline: none;
                border: none;

                +.slider {
                  box-shadow: 0 0 1px #2196f3;
                  border: none;
                  outline: 2px solid #6495ed;
                  outline-offset: 2px;
                }
              }

              &:checked+.slider:before {
                -webkit-transform: translateX(16px);
                -ms-transform: translateX(16px);
                transform: translateX(20px);
              }

              &:checked+.slider+.yesno:before {
                content: 'DA'
              }
            }

            .yesno {
              display: block;
              margin-left: 45px;
              font-family: $font-primary;
              font-style: normal;
              font-weight: bold;
              font-size: 15px;
              line-height: 21px;
              color: $secondary;

              &:before {
                content: 'NE';
              }
            }

            .check-square {
              //position: absolute;
              cursor: pointer;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              width: 22px;
              height: 22px;
              border: 2px solid $black;
              border-radius: 3px;
              background-color: $white;
            }
          }

          .text {
            margin-left: 12px;
            cursor: pointer;
            font-size: 12px;

            @include media-breakpoint-up(lg) {
              line-height: 24px;
              font-size: 16px;
            }
          }

          .newsletter-subscribe {
            margin-top: 4px;
            margin-left: 40px;
            margin-bottom: 40px;
            font-family: $font-primary;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            color: $secondary;
          }
        }
      }

      &.conformity-section,
      &.yearly-section,
      &.contract-section {
        .documents-grid {
          @include media-breakpoint-up(md) {
            grid-template-columns: 15% 15% 64% 6%;
          }

          .document-date {
            grid-column: 1;
            grid-row: 2;
            padding-top: 16px;
            padding-bottom: 16px;

            @include media-breakpoint-up(md) {
              grid-row: 1;
              padding-top: 0;
              padding-bottom: 0;
              grid-column: 1;
            }
          }

          .document-dateupdated {
            grid-column: 2;
            grid-row: 2;
            padding-top: 16px;
            padding-bottom: 16px;

            @include media-breakpoint-up(md) {
              grid-row: 1;
              padding-top: 0;
              padding-bottom: 0;
              grid-column: 2;
            }
          }

          .document-subject {
            grid-column: 1;
            grid-row: 4;
            padding-top: 16px;
            padding-bottom: 16px;

            @include media-breakpoint-up(md) {
              padding-top: 0;
              padding-bottom: 0;
              grid-column: 3;
              grid-row: 1;
            }
          }

          .document-more {
            grid-column: 2;
            grid-row: 4;
            justify-content: center;

            @include media-breakpoint-up(md) {
              grid-row: 1;
              grid-column: 4;
            }
          }
        }
      }
    }

    .payment-suggestions-section {
      h2 {
        margin-bottom: 32px;
      }

      .payment-suggestions-grid {
        display: grid;
        grid-template-columns: 50% 50%;

        @include media-breakpoint-up(md) {
          grid-template-columns: 25% 25% 25% 25%;
        }

        .element {
          display: flex;
          flex-direction: column;
          padding-top: 16px;
          padding-bottom: 16px;
          justify-content: center;

          @include media-breakpoint-up(md) {
            padding-left: 16px;
          }

          .element-title {
            font-size: 14px;
            line-height: 20px;
            color: $gray3;
            font-family: "Arimo", sans-serif;
            text-transform: capitalize;
          }

          .content {
            .icon {
              margin-right: 8px;
            }
          }
        }
      }

      .payment-suggestions-navi {
        border-bottom: 1px solid $red;

        display: none;

        @include media-breakpoint-up(md) {
          display: grid;
        }
      }

      .documents-payment-suggestions {
        border-bottom: 1px solid $gray3;
        grid-template-rows: minmax(66px, auto);

        .payment-suggestions-more {
          align-items: flex-end;
        }
      }

      .open-more {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        position: relative;

        &:hover {
          background-color: rgba($orange, 0.1);
        }

        &.open {
          .more-options {
            display: block;
          }
        }

        .more-options {
          position: absolute;
          background-color: $white;
          filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.25));
          z-index: 1;
          top: 40px;
          left: -132px;
          display: none;

          @media screen and(min-width:800px) {
            left: -163px;
          }

          // @include media-breakpoint-up(lg) {
          //   left: -64px;
          // }

          // @include media-breakpoint-up(xl) {
          //   left: -107px;
          // }

          // @media screen and (min-width:1570px) {
          //   left: -77px;
          // }

          // @media screen and (min-width:1700px) {
          //   left: -7px;
          // }

          a {
            color: $black;

            &:hover {
              color: $red;
              text-decoration: underline;
            }
          }

          .download {
            a {
              white-space: nowrap;
              padding: 13px 16px;
              position: relative;
              display: flex;
              align-items: center;

              &:before {
                margin-right: 4px;
                content: '';
                display: inline-block;
                width: 24px;
                height: 24px;
                background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_446_6718)'%3E%3Cpath d='M19 9H15V3H9V9H5L12 16L19 9ZM11 11V5H13V11H14.17L12 13.17L9.83 11H11ZM5 18H19V20H5V18Z' fill='%230C0C0C'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_446_6718'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A") no-repeat center center / cover;
              }

              &:hover {
                &:before {
                  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_446_6718)'%3E%3Cpath d='M19 9H15V3H9V9H5L12 16L19 9ZM11 11V5H13V11H14.17L12 13.17L9.83 11H11ZM5 18H19V20H5V18Z' fill='%23BA5F1B'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_446_6718'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A") no-repeat center center / cover;
                }
              }
            }
          }
        }
      }
    }


    ////OLD///

    .validation {
      padding: 13px 50px;
      background: #f6cccc;
      margin-bottom: 32px;

      p {
        margin: 0;
        padding: 0;
        font-family: $font-primary;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 30px;
        color: $red;
        text-align: center;
      }
    }

    .loginDivider {
      line-height: 0;
      text-align: center;
      margin-top: 30px;
      margin-bottom: 30px;
      color: #444444;
      display: flex;
      justify-content: space-between;

      .line {
        content: "";
        height: 1px;
        border-bottom: 1px solid #444444;
        width: calc(100% - 10px);

        &:first-child {
          margin-right: 10px;
        }

        &:last-child {
          margin-left: 10px;
        }
      }
    }

    #facebookBtn {
      //background: $fb-blue;
      width: 100%;
      height: 56px;
      color: white;
      font-size: 18px;
      font-family: Source Sans Pro;
      margin-bottom: 12px;
      border: none;
      position: relative;
      padding: 0;
      text-transform: uppercase;
      display: flex;
      align-items: center;

      span.buttonText {
        width: calc(100% - 54px);
      }

      span.icon {
        background: url(../../img/fb-icon.svg) transparent no-repeat;
        width: 24px;
        height: 24px;
        margin: 0 15px;
      }
    }

    #google-signin2 {
      .abcRioButton {
        font-family: Source Sans Pro;
        width: 100% !important;
        box-shadow: none;
        border: 1px solid #444444;
        text-transform: uppercase;

        .abcRioButtonContents {
          span {
            font-family: Source Sans Pro !important;
            font-size: 18px;
          }
        }
      }
    }

    // .fb-login-button {
    //   margin-bottom: 32px;
    //   background: $fb-blue;
    //   height: 56px;
    //   position: relative;
    //   outline: none;
    //   border: none;

    //   display: flex;
    //   width: 100%;
    //   color: $white;
    //   align-items: center;
    //   justify-content: center;

    //   .icon {
    //     position: absolute;
    //     left: 24px;
    //     top: 16px;

    //     &:before {
    //       content: "";
    //       display: block;
    //       background: url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M21.4286 0H2.57143C1.88944 0 1.23539 0.270918 0.753154 0.753154C0.270918 1.23539 0 1.88944 0 2.57143L0 21.4286C0 22.1106 0.270918 22.7646 0.753154 23.2468C1.23539 23.7291 1.88944 24 2.57143 24H9.92411V15.8405H6.54911V12H9.92411V9.07286C9.92411 5.74339 11.9063 3.90429 14.9421 3.90429C16.3961 3.90429 17.9164 4.16357 17.9164 4.16357V7.43143H16.2413C14.5907 7.43143 14.0759 8.45571 14.0759 9.50625V12H17.7605L17.1712 15.8405H14.0759V24H21.4286C22.1106 24 22.7646 23.7291 23.2468 23.2468C23.7291 22.7646 24 22.1106 24 21.4286V2.57143C24 1.88944 23.7291 1.23539 23.2468 0.753154C22.7646 0.270918 22.1106 0 21.4286 0Z' fill='white'/%3e%3c/svg%3e")
    //         no-repeat center center / contain;
    //       width: 24px;
    //       height: 24px;
    //     }
    //   }

    //   .text {
    //     color: $white;
    //   }
    // }

    // .g-login-button {
    //   margin-bottom: 25px;
    //   background: $white;
    //   border: 1px solid $black;
    //   padding: 16px 24px;

    //   a {
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     color: $black;

    //     .icon {
    //       margin-right: 55px;

    //       &:before {
    //         background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M23.5255 12.2762C23.5255 11.4605 23.4594 10.6404 23.3183 9.83789H12V14.4587H18.4815C18.2125 15.949 17.3483 17.2673 16.0829 18.1051V21.1033H19.9497C22.2204 19.0133 23.5255 15.9269 23.5255 12.2762Z' fill='%234285F4'/%3e%3cpath d='M11.9985 23.9999C15.2348 23.9999 17.964 22.9373 19.9526 21.1031L16.0857 18.1049C15.0099 18.8368 13.621 19.2513 12.0029 19.2513C8.87236 19.2513 6.21805 17.1393 5.26568 14.2998H1.27539V17.3906C3.31242 21.4426 7.46143 23.9999 11.9985 23.9999Z' fill='%2334A853'/%3e%3cpath d='M5.26233 14.2994C4.75968 12.8091 4.75968 11.1954 5.26233 9.70508V6.61426H1.27645C-0.425483 10.0049 -0.425483 13.9996 1.27645 17.3902L5.26233 14.2994Z' fill='%23FBBC04'/%3e%3cpath d='M11.9985 4.74947C13.7092 4.72301 15.3626 5.36675 16.6016 6.5484L20.0275 3.12249C17.8582 1.08546 14.979 -0.0344646 11.9985 0.000808633C7.46144 0.000808633 3.31242 2.55812 1.27539 6.61454L5.26127 9.70536C6.20924 6.86145 8.86796 4.74947 11.9985 4.74947Z' fill='%23EA4335'/%3e%3c/svg%3e")
    //           no-repeat center center / contain;
    //         width: 24px;
    //         height: 24px;
    //         content: "";
    //         display: block;
    //       }
    //     }
    //   }
    // }

    .sep {
      margin-bottom: 32px;
      width: auto;
      font-family: $font-primary;
      font-style: normal;
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 30px;
      text-align: center;
      color: $black;

      &:before,
      &:after {
        content: "";
        display: block;
        width: 35%;
        height: 1px;
        background: $secondary;
        margin: 0 7px;
      }

      @include media-breakpoint-up(md) {
        width: 100%;

        &:after,
        &:before {
          width: 250px;
        }
      }
    }



    .manage {
      p {
        font-family: $font-primary;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 15px;
        color: $secondary;

        a {
          text-decoration: underline;

          &:hover {
            color: $black;
            text-decoration: none;
          }
        }
      }
    }

    #user-account {
      h3 {
        font-family: $font-primary;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 29px;
        color: $secondary;
        margin-bottom: 0;
      }

      a {
        font-family: $font-primary;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 25px;
        color: $secondary;

        &.logout {
          span {
            display: inline-block;

            &:after {
              content: "";
              display: block;
              background: url("data:image/svg+xml;charset=UTF-8, %3csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M11 19.3334H3.49999C3.03998 19.3334 2.66667 18.96 2.66667 18.5001V3.50002C2.66667 3.04002 3.04002 2.66671 3.49999 2.66671H11C11.4608 2.66671 11.8333 2.29422 11.8333 1.83339C11.8333 1.37257 11.4608 1 11 1H3.49999C2.12166 1 1 2.1217 1 3.50002V18.5C1 19.8783 2.12166 21 3.49999 21H11C11.4608 21 11.8333 20.6275 11.8333 20.1667C11.8333 19.7059 11.4608 19.3334 11 19.3334Z' fill='%23D40000' stroke='%23D40000'/%3e%3cpath d='M20.8177 10.4064L15.751 5.40642C15.4243 5.08308 14.896 5.08727 14.5727 5.41476C14.2493 5.74226 14.2527 6.26975 14.581 6.59309L18.2018 10.1664H8.49933C8.0385 10.1664 7.66602 10.5389 7.66602 10.9997C7.66602 11.4606 8.0385 11.8331 8.49933 11.8331H18.2018L14.581 15.4064C14.2527 15.7297 14.2502 16.2572 14.5727 16.5847C14.736 16.7497 14.951 16.833 15.166 16.833C15.3777 16.833 15.5893 16.7531 15.751 16.593L20.8177 11.593C20.976 11.4364 21.066 11.223 21.066 10.9997C21.066 10.7764 20.9768 10.5639 20.8177 10.4064Z' fill='%23D40000' stroke='%23D40000'/%3e%3c/svg%3e") no-repeat center center / cover;
              width: 20px;
              height: 20px;
            }
          }
        }
      }

      #links {
        margin-bottom: 75px;

        a {
          font-size: 20px;
          line-height: 24px;
          color: $secondary;
          padding-right: 6px;

          &:first-child {
            border-right: 3px solid $red;
          }

          &:nth-child(2) {
            padding-left: 6px;
          }
        }
      }
    }

    .my-orders,
    .order-history {
      margin-bottom: 50px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 90px;
      }

      .order-element {
        margin-bottom: 25px;

        @include media-breakpoint-up(md) {
          margin-bottom: 30px;
        }

        .order-no {
          font-size: 14px;
          line-height: 18px;
          color: $black;
          padding-right: 24px;
          border-right: 3px solid $red;
          font-family: $font-bold;

          @include media-breakpoint-up(md) {
            font-size: 18px;
            line-height: 23px;
          }
        }

        a.order-details {
          font-size: 14px;
          line-height: 18px;
          text-decoration-line: underline;
          color: $secondary;
          padding-left: 22px;

          @include media-breakpoint-up(md) {
            font-size: 18px;
            line-height: 23px;
          }
        }

        .delivery {
          font-family: $font-primary;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 18px;
          color: $secondary;

          @include media-breakpoint-up(md) {
            font-size: 18px;
            line-height: 23px;
          }
        }
      }
    }

    .my-addresses,
    .my-orders,
    .order-history {
      margin-bottom: 50px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 90px;
      }

      h2 {
        font-size: 25px;
        line-height: 29px;
        color: $primary;
        margin-bottom: 24px;
        text-transform: none;
        font-family: $font-primary;
      }
    }

    .address-elements {
      .address-element {
        padding-bottom: 33px;
        margin-bottom: 33px;
        border-bottom: 1px solid $secondary;

        >div {
          font-size: 16px;
          line-height: 24px;
        }

        .default {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 12px;
          font-family: $font-primary;
        }

        .buttons {
          margin-top: 30px;

          @include media-breakpoint-up(lg) {
            margin-top: 45px;
          }
        }

        &:last-child {
          border: none;
          padding-bottom: 0px;
          margin-bottom: 0px;
        }
      }
    }

    .no-address-elements {
      margin-bottom: 33px;
    }
  }

  .registration-success {
    /*
    padding-bottom: 60px;
    
    @include media-breakpoint-up(lg) {
      padding-bottom: 120px;
    }*/

    .icon-reg-succ {
      //@include icon-reg-succ;
    }
  }

  .btn-edit {
    margin-right: 15px;

    @include media-breakpoint-up(md) {
      margin-right: 32px;
    }
  }
}