@mixin button ($bg, $text, $bghover, $texthover) {
  background-color: $bg;
  color: $text;
  text-align: center;
  font-size: 16px;
  line-height: 18px;
  padding-top: 12px;
  padding-right: 36px;
  padding-bottom: 12px;
  padding-left: 36px;
  font-family: $font-bold;
  border: 1px solid $bg;
  cursor: pointer;

  &:hover {
    background-color: $bghover;
    color: $texthover;
    border: 1px solid $bghover;
    transition: all 0.3s ease;
  }
}

.btn-primary {
  @include button($black, $white, $white, $black);
  border: 1px solid $black;

  display: inline-block;
}

.btn-primary-white {
  @include button($white, $black, $red, $white );
  border: 1px solid $black;
  display: inline-block;

  &:hover {
    span {
      color: $white;
    }
  }
}

.btn-transparent {
  @include button(rgba($white, 0), $black, $red, $white);
  border: 1px solid $black;

  display: inline-block;
}

.btn-orange {
  @include button($orange, $white, $red, $white);
  border: 1px solid $orange;

  display: inline-block;
}

@mixin hoverBase {
  --bg-h: 1px;
  transition: background-size 300ms;
  padding-bottom: 2px;

  &:where(:hover, :focus-visible) {
    background-size: 100% var(--bg-h);
    background-position-x: left;
  }
}

@mixin whiteHover {
  @include hoverBase;
  background: linear-gradient(0deg, #fff, #fff) no-repeat right bottom / 0 var(--bg-h);
}

@mixin orangeHover {
  @include hoverBase;
  background: linear-gradient(0deg, #e87722, #e87722) no-repeat right bottom / 0 var(--bg-h);
}

@mixin blackHover {
  @include hoverBase;
  background: linear-gradient(0deg, #000, #000) no-repeat right bottom / 0 var(--bg-h);
}

@mixin grayHover {
  @include hoverBase;
  background: linear-gradient(0deg, #868686, #868686) no-repeat right bottom / 0 var(--bg-h);
}


// Icons
@mixin iconPhone {
  content: '';
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_37_14103)'%3E%3Cpath d='M14.55 4.16667C14.5 4.90833 14.375 5.63333 14.175 6.325L15.175 7.325C15.5167 6.325 15.7333 5.26667 15.8083 4.16667H14.55ZM6.33333 14.1833C5.625 14.3833 4.9 14.5083 4.16667 14.5583V15.8C5.26667 15.725 6.325 15.5083 7.33333 15.175L6.33333 14.1833ZM13.75 2.5H16.6667C17.125 2.5 17.5 2.875 17.5 3.33333C17.5 11.1583 11.1583 17.5 3.33333 17.5C2.875 17.5 2.5 17.125 2.5 16.6667V13.7583C2.5 13.3 2.875 12.925 3.33333 12.925C4.36667 12.925 5.375 12.7583 6.30833 12.45C6.39167 12.4167 6.48333 12.4083 6.56667 12.4083C6.78333 12.4083 6.99167 12.4917 7.15833 12.65L8.99167 14.4833C11.35 13.275 13.2833 11.35 14.4833 8.99167L12.65 7.15833C12.4167 6.925 12.35 6.6 12.4417 6.30833C12.75 5.375 12.9167 4.375 12.9167 3.33333C12.9167 2.875 13.2917 2.5 13.75 2.5Z' fill='%23E87722'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_37_14103'%3E%3Crect width='20' height='20' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A") no-repeat center center / cover;  
}

@mixin iconMail {
  content: '';
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_37_14111)'%3E%3Cpath d='M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM20 18H4V8L12 13L20 8V18ZM12 11L4 6H20L12 11Z' fill='%23E87722'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_37_14111'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A") no-repeat center center / cover;  
}



@mixin transition($transition...) {
  @if length($transition) == 0 {
    $transition: $transition-base;
  }

  @if length($transition) > 1 {
    @each $value in $transition {
      @if $value == null or $value == none {
        @warn "The keyword 'none' or 'null' must be used as a single argument.";
      }
    }
  }

  @if $enable-transitions {
    @if nth($transition, 1) != null {
      transition: $transition;
    }

    @if $enable-prefers-reduced-motion-media-query and
      nth($transition, 1) !=
      null and
      nth($transition, 1) !=
      none
    {
      @media (prefers-reduced-motion: reduce) {
        transition: none;
      }
    }
  }
}

@mixin collapse {
  &:not(.show) {
    display: none;
  }
}

@mixin collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  @include transition($transition-collapse);
}

@mixin loader {
  .inner {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite;
    
    &::before ,
    &::after {
      content: "";
      box-sizing: border-box;
      position: absolute;
      inset: 0px;
      border-radius: 50%;
      border: 5px solid $black;
      animation: prixClipFix 2s linear infinite ;
    }
    &::after{
      border-color: $orange;
      animation: prixClipFix 2s linear infinite , rotate 0.5s linear infinite reverse;
      inset: 6px;
    }

    @keyframes rotate {
      0%   {transform: rotate(0deg)}
      100%   {transform: rotate(360deg)}
    }

    @keyframes prixClipFix {
      0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
      25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
      50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
      75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
      100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
    }
  }

  &.text-loader {
    flex-direction: column;
    background-color: rgba($orange, 0.3);
    backdrop-filter: blur(1px);
    .text {
      padding-top: 12px;
      color: $black;
      font-family: $font-bold;
    }
  }
}

@mixin loaderWhite {
  .inner {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite;
    
    &::before ,
    &::after {
      content: "";
      box-sizing: border-box;
      position: absolute;
      inset: 0px;
      border-radius: 50%;
      border: 5px solid $black;
      animation: prixClipFix 2s linear infinite ;
    }
    &::after{
      border-color: $white;
      animation: prixClipFix 2s linear infinite , rotate 0.5s linear infinite reverse;
      inset: 6px;
    }

    @keyframes rotate {
      0%   {transform: rotate(0deg)}
      100%   {transform: rotate(360deg)}
    }

    @keyframes prixClipFix {
      0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
      25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
      50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
      75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
      100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
    }
  }
}

@mixin loaderWhiteSmall {
  .inner {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite;
    
    &::before ,
    &::after {
      content: "";
      box-sizing: border-box;
      position: absolute;
      inset: 0px;
      border-radius: 50%;
      border: 2px solid $orange;
      animation: prixClipFix 2s linear infinite ;
    }
    &::after{
      border-color: $white;
      animation: prixClipFix 2s linear infinite , rotate 0.5s linear infinite reverse;
      inset: 6px;
    }

    @keyframes rotate {
      0%   {transform: rotate(0deg)}
      100%   {transform: rotate(360deg)}
    }

    @keyframes prixClipFix {
      0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
      25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
      50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
      75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
      100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
    }
  }
}