@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./fonts/arimo/Arimo-Regular.eot');
  src:url('./fonts/arimo/Arimo-Regular.eot?#iefix') format('embedded-opentype'),
      url('./fonts/arimo/Arimo-Regular.woff2') format('woff2'),
      url('./fonts/arimo/Arimo-Regular.woff') format('woff'),
      url('./fonts/arimo/Arimo-Regular.ttf') format('truetype'),
      url('./fonts/arimo/Arimo-Regular.svg#Arimo-Regular') format('svg');
}

@font-face {
  font-family: 'Arimo Bold';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./fonts/arimo/Arimo-Bold.eot');
  src:url('./fonts/arimo/Arimo-Bold.eot?#iefix') format('embedded-opentype'),
      url('./fonts/arimo/Arimo-Bold.woff2') format('woff2'),
      url('./fonts/arimo/Arimo-Bold.woff') format('woff'),
      url('./fonts/arimo/Arimo-Bold.ttf') format('truetype'),
      url('./fonts/arimo/Arimo-Bold.svg#Arimo-Bold') format('svg');
}

$font-primary: 'Arimo', sans-serif;
$font-bold: 'Arimo Bold', sans-serif;