* {
  outline: 0;
  box-sizing: border-box;

  &:focus-visible {
    outline: 2px solid $orange;
    outline-offset: 2px;
  }

  &:focus:not(:focus-visible) {
    outline: none;
    box-shadow: none;
  }

  &::selection {
    background: rgba(232, 119, 34, 1);
    color: $white;
  }
}

body {
  background: $white;
  font-family: $font-primary;
  color: $black;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  overflow: auto;
  margin: 0;
  margin-bottom: 0!important;

  a {
    text-decoration: none;
    color:$black;

    &:hover {
      text-decoration: none;
      color:$black;
    }
  }

  p {
    margin-bottom: 0;
  }

  h1,
  h2,
  h3,
  h4,
  .main-cookie-title {
    font-family: $font-bold;
    margin: 0;
  }

  &.overflow {
    overflow: hidden;
  }


  &.overflow {
    overflow: hidden;
  }
}

h1 {
  font-size: 37px;
  line-height: 41px;

  @include media-breakpoint-up(md) {
    font-size: 64px;
    line-height: 77px;
  }
}

h2{
  font-size: 30px;
  line-height: 35px;

  @include media-breakpoint-up(md) {
    font-size: 50px;
    line-height: 58px;
  }
}

.main-cookie-title {
  font-size: 30px;
  line-height: 35px;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  clip: rect(0px, 0px, 0px, 0px);
  white-space: nowrap;
  padding: 0px;
  margin: -1px;
  overflow: hidden;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
}

.img-fluid {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: rgb(73, 80, 87);
  background-color: rgb(255, 255, 255);
  background-clip: padding-box;
  padding: 0.375rem 0.75rem;
  border-width: 1px;
  border-style: solid;
  border-color: $black;
  border-image: initial;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

strong,
b {
  font-family: $font-bold;
}

.btn.focus,
.btn:focus {
  box-shadow: none;
}

input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.splide__arrows {
  svg {
    display: none;
  }
}

.table-normal {
  td {
    border-top: 0;
    width: auto !important;

    &:first-child {
      padding-left: 0;
    }
  }
}

.table-borders {
  tr {
    border-bottom: 1px solid $gray;

    td {
      border-top: 0;
      width: auto !important;
    }
  }
}

.table-responsive {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      @include media-breakpoint-down($breakpoint) {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;

        > .table-bordered {
          border: 0;
        }
      }
    }
  }
}

.p-0 {
  padding: 0;
}

#error-page {
  padding-bottom: 48px;
  padding-top: 48px;

  @include media-breakpoint-up(md) {
    padding-bottom: 76px;
    padding-top: 76px;
  }
}

.error-content {
  display: flex;
  align-items: center;

  h1 {
    font-size: 36px;
    line-height: 42px;

    @include media-breakpoint-up(md) {
      font-size: 61px;
      line-height: 71px;
    }
  }

  a {
    color: $orange;
    @include orangeHover;

    &:hover {
      color: $orange;
    }
  }
}


.default-page {
  padding-top: 48px;
  padding-bottom: 48px;
  @include media-breakpoint-up(md) {
    padding-top: 96px;
    padding-bottom: 96px;
  }
  

  h1 {
    margin-bottom: 46px;
  }
}


.mobile-navi {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  z-index: 100;
  background-color: $white;
  border-top: 1px solid $orange;
  justify-content: center;
  padding: 16px;

  .acc-item  {
    margin-right: 24px;
    text-align: center;
    position: relative;
    @include blackHover;

    @include media-breakpoint-up(lg) {
      margin-right: 32px;
    }

    // &:last-of-type {
    //   margin-right: 0;
    // }

    .icon-wrapper {
      position: relative;
    }

    .count {
      position: absolute;
      min-width: 20px;
      min-height: 20px;
      padding-left: 4px;
      padding-right: 4px;
      content: '';
      background-color: $orange;
      border-radius: 50%;
      top: -10px;
      right: -10px;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      line-height: 14px;
    }

    &.active {
      background-size: 100% var(--bg-h);
      background-position-x: left;
    }
  }

  span {
    font-family: $font-bold;
    font-size: 12px;
    line-height: 17px;
    color: $black;
  }

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

#footer {
  padding-bottom: 93px;

  @include media-breakpoint-up(lg) {
    padding-bottom: 0;
  }
}

#help {
  position: fixed;
  right: 24px;
  bottom: 24px;
  content: '';
  background-color: $white;
  border: 1px solid $orange;
  z-index: 1000;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @include media-breakpoint-up(lg) {
    display: flex;
  }

  .inner {
    width: 50px;
    height: 50px;
    content: '';
    display: inline-block;
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1724_21270)'%3E%3Cpath d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 19H11V17H13V19ZM15.07 11.25L14.17 12.17C13.45 12.9 13 13.5 13 15H11V14.5C11 13.4 11.45 12.4 12.17 11.67L13.41 10.41C13.78 10.05 14 9.55 14 9C14 7.9 13.1 7 12 7C10.9 7 10 7.9 10 9H8C8 6.79 9.79 5 12 5C14.21 5 16 6.79 16 9C16 9.88 15.64 10.68 15.07 11.25Z' fill='%23e87722'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1724_21270'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A") no-repeat center center / cover;
  }
}

.mobile-help {
  margin-right: 0!important;
  .icon {
    width: 24px;
    height: 24px;
    content: '';
    display: inline-block;
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1724_21270)'%3E%3Cpath d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 19H11V17H13V19ZM15.07 11.25L14.17 12.17C13.45 12.9 13 13.5 13 15H11V14.5C11 13.4 11.45 12.4 12.17 11.67L13.41 10.41C13.78 10.05 14 9.55 14 9C14 7.9 13.1 7 12 7C10.9 7 10 7.9 10 9H8C8 6.79 9.79 5 12 5C14.21 5 16 6.79 16 9C16 9.88 15.64 10.68 15.07 11.25Z' fill='%23e87722'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1724_21270'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A") no-repeat center center / cover;
  }
}

#help-form {
  position: fixed;
  right: -100%;
  bottom: 150px;
  width: 300px;
  background-color: $white;
  border: 1px solid $orange;
  z-index: 1000;
  padding: 24px;
  transition: all 0.3s ease;

  .contact-form-form {
    #contact_form {
      margin-bottom: 0;
    }

    textarea {
      border-radius: 0;
      min-height: 100px;
    }

    .send-button {
      margin-bottom: 0;

      button {
        width: 100%;
      }
    }
  }

  &.show-help {
    right: 0
  }

  .text {
    margin-bottom: 24px;

    h2 {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 8px;
      display: block;
    }

    p {
      &:first-of-type {
        margin-top: 0;
      }
    }
  }
}

// .was-validated :invalid~.invalid-feedback, .was-validated :invalid~.invalid-tooltip, .is-invalid~.invalid-feedback, .is-invalid~.invalid-tooltip {
//   display: block;
// }

.was-validated :invalid~.invalid-tooltip-form, .is-invalid~.invalid-tooltip-form {
display: block;
}

.invalid-tooltip-form {
  position: absolute;
  top: -24px;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: .875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220,53,69,.9);
  border-radius: 0;
}

.help-tooltip {
  position: absolute;
  padding: 16px;
  border: 1px solid $lightgray6;
  border-radius: 8px;
  background: $white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  display: none;
  z-index: 1001;
  text-align: left;
  width: 255px;

  &:before {
    width: 12px;
    height: 12px;
    content: '';
    display: block;
    left: 50%;
    top: -7px;
    background: $white;
    position: absolute;
    transform: translateX(-50%) rotate(45deg);
    border-left: 1px solid $lightgray6;
    border-top: 1px solid $lightgray6;
  }

  p{
    //white-space: nowrap;
    &:first-of-type {
      margin-top: 0;
    }
  }
}

.link-underline {
  color: $orange;
  @include orangeHover;

  &:hover {
    color: $orange;
  }
}

.table {
  &.help {
    tr {
      display: flex;
      justify-content: space-evenly;
      flex-direction: column;
      align-items: center;

      @include media-breakpoint-up(md) {
        flex-direction: row;
      }
    }
    td {
      border: none!important;
      width: 80%!important;
      color: $gray;

      @include media-breakpoint-up(md) {
        width: 25%!important;
      }
    }
  }
}

ul.level1 {
  li.stresni-sistemi {
    @include media-breakpoint-up(md) {
      &:hover {
        .help-tooltip {
          display: block;
          left: -78px;
          top: 39px;
        }
      }
    }
  }
}