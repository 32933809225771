.account-login {
    .top-header {
        display: block;
    }
}
.start-login {
    overflow: hidden;

    @include media-breakpoint-up(md) {
        height: calc(100vh - 142px);
        position: relative;
        display: flex;
        align-items: center;
        
    }

    .start-header {
        margin-bottom: 48px;
        
        @include media-breakpoint-up(md) {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            z-index: 2;
            margin-bottom: 0;
        }
    }

    .login-background {
        position: absolute;
        top: 0;
        left: 0;
        height: 200%;
        width: 100%;
        content: '';
        display: none;
        flex-direction: column;
        transform: translateY(-25%) skewY(6deg);

        @include media-breakpoint-up(md) {
            flex-direction: row;
            transform: translateX(-25%) skewX(-6deg);
            height: 100%;
            width: 200%;
            display: flex;
        }

        .orange {
            width: 200%;
            height: 50%;
            content: '';
            background-color: $orange;

            @include media-breakpoint-up(md) {
                width: 50%;
                height: 100%;
            }
        }

        .white {
            width: 200%;
            height: 50%;
            content: '';
            background-color: $white;

            @include media-breakpoint-up(md) {
                width: 50%;
                height: 100%;
            }
        }
    }

    .login-content {
        @include media-breakpoint-up(md) {
            padding-top: 60px;
        }
        .user-login {
            padding-bottom: 96px;
            @include media-breakpoint-up(md) {
                padding-bottom: 0;
            }

            .validation {
                margin-bottom: 46px;
                background-color: $red;
                padding: 8px;
                color: $white;
                display: block;
            }
        }
        .login {
            @include media-breakpoint-up(md) {
                padding-top: 44px;
            }
            h2 {
                margin-bottom: 60px;
            }

            form {
                .form-group {
                    margin-bottom: 42px;
                    position: relative;

                    .label {
                        position: absolute;
                        top: -22px;
                        left: 0;
                        font-size: 14px;
                        line-height: 20px;
                    }

                    input {
                        max-width: none;
                        border: none;
                        border-bottom: 1px solid $black;
                        height: 31px;
                        padding: 0;
                    }
                }
            }

            .manage {
                a {
                    font-family: $font-bold;
                    text-decoration: underline;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        .info {
            color: $white;
            background-color: $orange;
            padding-bottom: 96px;
            padding-top: 48px;
            transform: skewY(6deg);
            margin-bottom: -48px;

            @include media-breakpoint-up(md) {
                transform: none;
                padding-bottom: 0;
                padding-top: 0;
                margin-bottom: 0;
            }

            h2 {
                @include media-breakpoint-up(md) {
                    font-size: 42px;
                    line-height: 48px;
                }
            }

            .content-inner {
                transform: skewY(-6deg);

                @include media-breakpoint-up(md) {
                    transform: none;
                }
            }
        }


        .login-container {
            &.hidden {
                display: none;
            }

            &.show {
                display: block;
            }
        }

        .reset-pass-container {
            &.hidden {
                display: none;
            }

            &.show {
                display: block;
            }

            .reset-password {
                .col-md-6 {
                    padding: 0;
                }
            }
        }

        .login-container-link,
        .reset-pass-link {
            font-family: $font-bold;
            text-decoration: underline;
            cursor: pointer;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

.reset-header {
    .top-header {
        display: block;
    }
}

.login-link {
    color: $orange;
    @include orangeHover;

    &:hover {
        color: $orange;
    }
}

.reset-pass {
    height: calc(100vh - 158px);
    padding-bottom: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @include media-breakpoint-up(md) {
        height: calc(100vh - 142px);
    }
    
    .container {
        z-index: 10;
    }

}

.countdown {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10000;
    background-color: $orange;


    .countdown-inner {
        width: 100%;
        overflow: scroll;
        height: 100%;

        @include media-breakpoint-up(md) {
            display: flex;
            align-items: center;
        }
    }

    .login-content {
        padding-bottom: 124px;
    }

    .lyform {
        height: 400px;
        position: relative;
        display: block;
    }

    .lyforms {
        min-height: 264px;
    }

    .start-header {
        z-index: 1;
        position: relative;
        padding-top: 16px;
        padding-bottom: 16px;
        margin-bottom: 0;

        @media screen and (min-width:390px) {
            margin-bottom: 16px;
        }

        @include media-breakpoint-up(md) {
            position: absolute;
            background-color: transparent;
            margin-bottom: 48px;
        }

        .row {
            justify-content: center;

            @include media-breakpoint-up(md) {
                justify-content: start;
            }
        }
    }

    .login-background {
        display: flex;

        .orange {
            height: 150%;
            @include media-breakpoint-up(md) {

                width: 80%;
            }

            @include media-breakpoint-up(lg) {
                width: 50%;
            }
        }
        .white {
            height: 0;
            @include media-breakpoint-up(md) {
                height: 100%;
            }
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                display: block;
                margin-left: 215px;

                @include media-breakpoint-up(lg) {
                    margin-left: 300px;
                }

                @media screen and(min-width:1400px) {
                    margin-left: 350px;
                }

                @media screen and(min-width:1600px) {
                    margin-left: 400px;
                }

                @media screen and(min-width:1800px) {
                    margin-left: 450px;
                }

                @media screen and(min-width:2000px) {
                    margin-left: 650px;
                }
            }
        }
    }

    .content {
        &.top {
            font-family: $font-bold;
            color: $white;
            font-size: 24px;
            line-height: 32px;
            text-align: center;
            margin-bottom: 24px;

            // @media screen and (min-width:390px) {
            //     margin-bottom: 64px;
            // }

            @include media-breakpoint-up(lg) {
                margin-bottom: 64px;
            }
        }
    }
    
    ul#countdown {
        list-style: none;
        display: flex;
        padding: 0;
        margin-bottom: 24px;
        justify-content: center;

        // @media screen and (min-width:390px) {
        //     margin-bottom: 64px;
        // }

        @include media-breakpoint-up(lg) {
            margin-bottom: 64px;
        }

        li {
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: $white;
            padding: 8px;
            margin-right: 12px;
            min-width: 74px;

            @include media-breakpoint-up(md) {
                margin-right: 16px;
                min-width: 92px;
            }

            .number {
                font-size: 30px;
                line-height: 34px;
                font-family: $font-bold;

                @include media-breakpoint-up(md) {
                    font-size: 50px;
                    line-height: 54px;
                }
            }

            .label {
                font-size: 16px;
                line-height: 20px;
            }

            &:last-of-type {
                margin-right: 0;
            }
        }
    }

    .lyform {
        
        .content {
            color: $white;
            text-align: center;
            margin-bottom: 18px;

            // @media screen and (min-width:390px) {
            //     margin-bottom: 48px;
            // }

            @include media-breakpoint-up(lg) {
                margin-bottom: 48px;
            }
        }
    }


    &.new {
        .countdown-inner {
            display: block;
            overflow: unset;

            .login-wrapper {
                width: 100%;
                height: 100%;

                .login-wrapper-inner {
                    display: flex;
                    width: 100%;
                    height: 100%;

                    .login-left {
                        width: 40%;
                        display: none;

                        @include media-breakpoint-up(md) {
                            display: block;
                        }

                        @include media-breakpoint-up(lg) {
                            width: 50%;
                        }
                        
                        img {
                            object-fit: cover;
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .login-right {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        overflow-x: auto;
                        padding-top: 25px;
                        padding-bottom: 100px;
                        padding-left: 16px;
                        padding-right: 16px;

                        @include media-breakpoint-up(md) {
                            padding-top: 125px;
                            width: 60%;
                            padding-left: 0;
                            padding-right: 0;
                        }

                        @include media-breakpoint-up(lg) {
                            width: 50%;
                        }

                        .login-obrazec {
                            max-width: 420px;
                            height: 700px;
                        }
                    }
                }
            }
        }
    }
}

.youtube-video {
    float: none;
    clear: both;
    width: 100%;
    position:
    relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
    margin-bottom: 24px;
}

.youtube-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
} 