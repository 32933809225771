form {
  margin-bottom: 32px;

  .external-login-wrapper {
    margin-top: 32px;

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
  }
  .external-login {
    font-family: $font-primary;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 12px;

    @include media-breakpoint-up(md) {
      margin-bottom: 12px;
    }
  }

  .form-group {
    margin-bottom: 14px;
    position:relative;

    @include media-breakpoint-up(lg) {
      margin-bottom: 24px;
    }

    &.text-group {
      margin-bottom: 12px;
      .text {
        font-size: 13px;
        line-height: 18px;
        padding: 8px;
        background: #f2f7fa;
        position:relative; 
        top: -10px;

        @include media-breakpoint-up(md) {
          font-size: 14px;
          line-height: 19px;
        }
      }
    }

    input {
      border: 1px solid $black;
      border-radius: 0;
      max-width: 314px;
      width: 100%;
    }


    .form-control {
      width: 100%;
      height: 40px;
      margin-bottom: 0;
      border-color: #545457;
      padding: 0 8px;
      color:$primary;
      
      @include media-breakpoint-up(lg) {
        height: 48px;
      }

      /*
      &:focus {
        color: $primary;;
        background-color: #fff;
        border-color: #545457;
        outline: 0;
        box-shadow: none;
      }*/

      &:disabled, &[readonly] {
        background:#ececec;
      }

      &:disabled, &[readonly] {
        background:#fff;
        color:$primary;
      }

      &#country_id {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        text-indent: 1px;
        cursor: pointer;

        &:after {
          content:'';
        }
      }

      &.comment {
        height: auto;
        padding: 20px 15px;
        font-size: 15px;
        line-height: 21px;
        font-family: $font-primary;
      }
      
      &.textarea {
        padding-top: 15px;
        height: auto;
      }
    }

    &.comment {
      margin-top: 30px;
      padding-left: 0;
      padding-right: 0;

      @include media-breakpoint-up(lg) {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }

  .control-label {
    display: block;
    position: absolute;
    opacity: 0;
    padding: 0 5px;
    top: 1px;
    left: 12px;
    color: $secondary;
    margin-bottom: 0;
    transition: 0.2s ease-in-out transform;
    font-size: 12px;
  }

  .form-control:placeholder-shown + .control-label {
    visibility: hidden;
    z-index: -1;
    transition: 0.2s ease-in-out;
  }
  .form-control:not(:placeholder-shown) + .control-label,
  .form-control:focus:not(:placeholder-shown) + .control-label {
    visibility: visible;
    z-index: 1;
    opacity: 1;
    background: #fff;
    transform: translateY(-10px);
    transition: transform 0.2s ease-in-out, background 0.25s ease-in;
  }

  &.was-validated {
    .form-group {
      input {
        &:invalid {
          border-color: #dc3545;
          background: none;
        }
      }

      .form-control {
        &:valid, &.is-valid {
          border-color: $primary;
          background: none;
          &:focus {
            border-color: $primary;
          }
        }
      }
    }

    .valid-feedback,
    .invalid-feedback {
      position: absolute;
      left: 0;
    }

    .invalid-feedback {
      margin-top: 0;
      padding-top: 2px;
      padding-bottom: 2px;
    }
  }

  .btn-submit {
    display: block;
    //margin: 40px auto 0;
  }

  .form-group-switch {
    display: flex;
    margin-top: 8px;
    margin-bottom: 27px;

    label.switch {
      position: relative;
      display: flex;
      margin-bottom: 0;

      a {
        text-decoration: underline;
      }

      input {
        -ms-appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        display: none;

        &:checked + .slider {
          background-color: $green;
        }

        &:checked + .check-square {
          background-color: $red;
          border: 2px solid $red;
          display: flex;
          justify-content: center;
          align-items: center;

          &:before {
            background: url("data:image/svg+xml,%3Csvg width='18' height='14' viewBox='0 0 18 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.00039 11.2001L1.80039 7.0001L0.400391 8.4001L6.00039 14.0001L18.0004 2.0001L16.6004 0.600098L6.00039 11.2001Z' fill='%23ffffff'/%3E%3C/svg%3E%0A") no-repeat center center / cover;
            display: inline-block;
            width: 15px;
            height: 12px;
            content: '';
          }
        }

        &:focus {
          outline: none;
          border: none;
          + .slider {
            box-shadow: 0 0 1px #2196f3;
            border: none;
            outline:2px solid #6495ed;
            outline-offset: 2px;
          }
        }

        &:checked + .slider:before {
          -webkit-transform: translateX(16px);
          -ms-transform: translateX(16px);
          transform: translateX(20px);
        }

        &:checked + .slider + .yesno:before {
          content:'DA'
        }
      }

      .yesno {
        display: block;
        margin-left: 45px;
        font-family: $font-primary;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 21px;
        color: $secondary;

        &:before {
          content: 'NE';
        }
      }

      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $primary;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 34px;
        width: 40px;
        height: 20px;

        &:before {
          position: absolute;
          content: "";
          height: 16px;
          width: 16px;
          left: 2px;
          bottom: 2px;
          background-color: white;
          -webkit-transition: 0.4s;
          transition: 0.4s;
          border-radius: 50%;
        }
      }

      .check-square {
        //position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 24px;
        height: 22px;
        border: 2px solid $black;
        border-radius: 3px;
        background-color: $white;

        @include media-breakpoint-up(md) {
          width: 100%;
          max-width: 22px;
        }
      }
    }

    .text {
      margin-left: 12px;
      cursor: pointer;
      font-size: 12px;
      
      @include media-breakpoint-up(lg) {
        line-height: 24px;
        font-size: 16px;
      }
    }

    .newsletter-subscribe {
      margin-top: 4px;
      margin-left: 40px;
      margin-bottom: 40px;
      font-family: $font-primary;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: $secondary;
    }
  }

  .form-errors {
    display: none;
  }
  &.has-errors {
    .form-errors {
      display: block;
      font-family: $font-bold;
      margin-top: 10px;
      color:$red;
    }
  }
}

.radio-container {
  .form-check {
    padding: 0;
    label {
      cursor: pointer;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      margin:0 0 20px;
      padding: 0;
      color: $primary;

      .radio_input {
        display: grid;
        grid-template-columns: -webkit-min-content auto;
        grid-template-columns: min-content auto;
        grid-gap: 0.5em;
        align-items:center;
        font-size: 2.25rem;
        flex:1;
      
        .radio_control {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 22px;
          height: 22px;
          border-radius: 50%;
          border: 2px solid $primary;

          &:before {
            content: "";
            width: 14px;
            height: 14px;
            box-shadow: inset 0.5em 0.5em $red;
            border-radius: 50%;
           // transition: 180ms transform ease-in-out;
            transform: scale(0);
          }
        }

        .radio_label {
          font-size: 16px;
          line-height: 24px;
          font-family: $font-primary;
          font-weight: 400;
          
          @include media-breakpoint-up(lg) {
            font-size: 20px;
            line-height: 27px;
          }
        }
      }

      .type-content {
        width: 100%;
        padding: 0 42px;
        height: 0;
        opacity: 0;
      }

      input {
        opacity: 0;
        width: 0;
        height: 0;
        margin: 0;
        position: relative;

        &:focus + .radio_control {
          box-shadow: 0 0 0 0.05em #fff, 0 0 0.15em 0.1em $primary;
        }

        &:checked + .radio_input {
          .radio_control {
            //box-shadow: 0 0 0 0.05em #fff, 0 0 0.15em 0.1em $primary;
            
            &:before {
              transform: scale(1);
            }
          }

          .radio_label {
            font-family: $font-bold;
          }
        }

        &:checked ~ .type-content {
          padding: 20px 42px 10px;
          height: auto;
          opacity: 1;
        }
      }      
    }
  }
}

.user-register {
  .register-tabs {
    margin-bottom: 25px;
    ul {
      display: inline-flex;

      li {
        margin-right: 30px;

        a {
          text-transform: uppercase;
          color: #87787c;
          font-family: "Roboto Medium",sans-serif;
          letter-spacing: 1px;

          &.active {
            color: #000;
          }
        }
      }
    }
  }
}

.company-register-form {
  .form-group-switch.taxable {
    margin-bottom: 0;
  }

  #taxableCont {
    .form-group, .form-group-switch {
      flex: 1 1 50%;

      .switch {
        width: 100%;
        margin-left: 20px;
        margin-bottom: 25px;
        display: flex;
        align-items: center;

        .check-square {
          margin-right: 15px;
        }
      }
    }
  }
}

.email-label {
  width: 100%;
  display: grid;

  input {
    padding: 14px;
  }
}

.invalid-feedback {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: .875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220,53,69,.9);
  border-radius: 0.25rem;
}

.was-validated :invalid~.invalid-feedback {
  display: block;
}