#cart-full {  
  padding-bottom: 48px;
  min-height: 300px;
  position: relative;

  @include media-breakpoint-up(lg){
    padding-bottom: 98px;
  }

  h2 {
    text-align: left;
    margin-bottom: 20px;
    
    @include media-breakpoint-up(lg) {
      margin-bottom: 64px;
    }
  }

  .cart-full-grid {
    margin-bottom: 30px;

    .items-in-cart {
      h2 {
        margin-top: 0;
        margin-bottom: 48px;
      }
    }
  }

  .empty-cart {
    padding-top: 48px;

    @include media-breakpoint-up(lg) {
      padding-top: 0px;
    }

    .empty-cart-text {
      margin-bottom: 68px;
      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }

      h2 {
        margin-top: 0;
        text-align: left;
      }
      p {
        margin-bottom: 36px;
      }
    }
    .empty-cart-info {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .empty-cart-payment-types {
        margin-bottom: 24px;
        display: flex;
        align-items: center;

        span {
          font-family: $font-bold;
        }
      }
      .empty-cart-payment-info {
        display: flex;
        flex-direction: column;
        a{
          margin-bottom: 24px;
          text-decoration: underline;

          &:last-of-type {
            margin-bottom: 0;
          }

          &:hover {
            text-decoration: none; 
          }
        }
      }
    }
  }

  .cartfull-products-list {
    h2 {
      margin-top: 0;
      margin-bottom: 48px;
    }

    .list-loader {
      @include loader;
    }

    .product {
      position:relative;
      display: flex;
      flex-direction: column;

      border-bottom: 1px solid $gray;
      padding-bottom: 32px;
      margin-bottom: 32px;
      max-width: 100%;
      
      @include media-breakpoint-up(lg) {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: auto;
        gap: 0;
        grid-template-areas:
          "name"
          "price"
          "stock"
          "rebate",
          "quantity"
          "remove";
        grid-template-columns: 80% 20%;
        grid-template-areas:
        "name price"
        "stock rebate"
        "quantity remove";
      }

      .name {
        grid-area: name;
        margin-bottom: 8px;
        .product-code {
          color:$gray;
          margin-bottom: 8px;
          font-size: 14px;
        }

        h3 {
          font-size: 21px;
            line-height: 29px;
            margin-bottom: 0;

          a {
            color:$primary;
          }
        }
      }
      
      .stock-container {
        grid-area: stock;
        margin-bottom: 24px;

        .onstock {
          color: $green;
        }

        .nostock {
          color: $red2;
        }
      }

      .rebate-container {
        grid-area: rebate;
        padding-top: 12px;
        padding-bottom: 16px;

        font-size: 16px;
        color: $gray3;

        .content {
          width: 44px;
          margin-left: 8px;
          display: flex;
          justify-content: flex-end;
        }

      }

      .quantity-container {
        grid-area: quantity;
        position: relative;
        &:hover {
          @include media-breakpoint-up(md) {
            .help-tooltip {
              display: block;
              top: 64px;
  
              &:before {
                left: 75px;
              }
            }
          }
        }
        
        .quantity {
          margin: 0 16px 0 0;
          border: 1px solid $gray;
          width: 100%;
          width: 150px;
          position: relative;
          
          @include media-breakpoint-up(sm) {
          }

          .list-loader {
            @include loader;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            align-items: center;
          }
          
          .btn {
            position:relative;
            height: 46px;
            width: 35px;
            min-width: 0px;
            border: none;
            background: none;
            cursor: pointer;
            position:absolute;
            top: 1px;
            left: 0px;

            @include media-breakpoint-up(sm) {
            }

            &.btn-plus {
              right: 0px;
              left: auto;

              &:before, &:after {
                position: absolute;
                top: 17px;
                right: 20px;
                content: '';
                height: 12px;
                width: 2px;
                background-color: $black;
              }
              
              &:before {
                transform: rotate(90deg);
              }
            }

            &.btn-minus {
              &:before {
                position: absolute;
                top: 22px;
                left: 11px;
                content: '';
                height: 2px;
                width: 12px;
                background-color: $black;
              }
            }
          }

          input {
            width: 100%;
            height: 48px;
            border: none;
            font-size: 16px;
            text-align:center;
            width: 148px;

            @include media-breakpoint-up(sm) {
              padding-left: 45px;
              padding-right: 45px;
            }
          }
          
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          
          input[type=number] {
            -moz-appearance: textfield;
          }
        }
      }

      .product-price {
        grid-area: price;
        margin-left: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .price {
          text-align:right;
  
          .price-new {
            font-size: 16px;
            line-height: 20px;
            font-family: $font-bold;
            white-space: nowrap;
            margin-bottom: 8px;
  
            @include media-breakpoint-up(lg) {
              font-size: 20px;
              line-height: 30px;
              margin-top: 6px;
            }
          }
  
          .old-price {
            text-decoration: line-through;
            color: $gray;
            font-size: 16px;
            margin-bottom: 8px;
          }
          
          .price-withvat {
            font-size: 12px;

          }
        }
      }

      .remove-button {
        grid-area: remove;
        margin-top: 24px;
        
        @include media-breakpoint-up(sm) {
          margin-top: 0;
        }
        a.btn-remove {
          .icon-trash {
            margin-right: 9px;
          }

          span {
            font-size: 16px;
            line-height: 18px;
            text-align: right;
            font-family: $font-bold;
          }
        }
      }
    }

    .inquiry-notes {
      display: flex;
      flex-direction: column;
      margin-top: 24px;

      label {
        font-family: $font-bold;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 12px;
      }

      textarea {
        min-height: 169px;
        font-family: $font-primary;
        font-size: 14px;
        line-height: 18px;
        padding: 16px;
      }
    }
  }

  #order-details {
    position:relative;
    

    .order-summary {
      padding: 24px;
      background: $lightgray;
      
      h2 {
        margin-top: 0;
        margin-bottom: 24px;
        font-size: 38px;
        line-height: 44px;
      }

      .unit-price,
      .price {
        font-size: 16px;
        line-height: 24px;
        font-family: $font-primary;
      }

      .send-order {
        margin-bottom: 16px;
      }
    }

    .to-checkout {
      a {
        width: 100%;
        text-align: center;
      }
    }

    .additional-info {
      margin-top: 32px;
      .element {
        margin-bottom: 14px;

        .icon {
          margin-right: 12px;
        }
      }
    }


    .list-loader {
      position: fixed;
    }
  }
}

#order-finalize {
  @include media-breakpoint-up(md) {
    padding-top: 18px;
  }
  margin-bottom: 96px;

  h1 {
    font-size: 37px;
    line-height: 41px;
    margin-bottom: 48px;
    display: flex;
    align-items: center;

    @include media-breakpoint-up(lg) {
      font-size: 48px;
      line-height: 58px;
    }

    &:after {
      display: inline-block;
      content: '';
      width: 26px;
      height: 26px;
      margin-left: 5px;
      background: url("data:image/svg+xml;charset=UTF-8,%3csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M7.00065 0.333008C3.32065 0.333008 0.333984 3.31967 0.333984 6.99967C0.333984 10.6797 3.32065 13.6663 7.00065 13.6663C10.6807 13.6663 13.6673 10.6797 13.6673 6.99967C13.6673 3.31967 10.6807 0.333008 7.00065 0.333008ZM7.00065 12.333C4.06065 12.333 1.66732 9.93967 1.66732 6.99967C1.66732 4.05967 4.06065 1.66634 7.00065 1.66634C9.94065 1.66634 12.334 4.05967 12.334 6.99967C12.334 9.93967 9.94065 12.333 7.00065 12.333ZM10.0607 4.05301L5.66732 8.44634L3.94065 6.72634L3.00065 7.66634L5.66732 10.333L11.0007 4.99967L10.0607 4.05301Z' fill='%2300893A'/%3e%3c/svg%3e") no-repeat center center/cover;

      @include media-breakpoint-up(md) {
        width: 46px;
        height: 46px;
      }
    }
  }

  .document-number {
    font-family: $font-bold;
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 24px;
  }
}

#order-empty {
  padding: 35px 0 0;
  @include media-breakpoint-up(sm) {
    padding: 65px 0 0;
  }
  h1 {
    color:$primary;
    font-size: 32px;
    font-family: $font-bold;
    text-transform: uppercase;
    text-align:center;
    margin-bottom:30px;
    
    @include media-breakpoint-up(md) {
      margin-bottom:65px;
      font-size: clamp(1.75rem, 5vw, 48px);
    }
  }
  
  h2 {
    font-size: 20px;
    
    @include media-breakpoint-up(md) {
      font-size: 25px;
    }
  }

  p {
    font-size: 14px;
    
    @include media-breakpoint-up(md) {
      font-size: 18px;
    }
  }

  .empty-order {
    margin-bottom: 60px;
    padding-top: 50px;
    
    @include media-breakpoint-up(md) {
      margin-bottom: 120px;
    }
    @include media-breakpoint-up(lg) {
      padding-top: 0px;
    }

    .icon {
      //@include icon-order-empty;
      margin: 0 auto 20px;
    }

    .text {
      font-size: 15px;

      @include media-breakpoint-up(md) {
        font-size: 18px;
      }
    }
  }
}

#delivery {
  background:$lightgray3;
  padding: 24px 16px;

  h3 {
    margin-bottom: 24px;
    font-family: $font-bold;
    font-size: 28px;
    line-height: 32px;
  }

  .delivery-select {
    position: relative;
    background-color: $white;
    select {
      position: relative;
      width: 100%;
      height: 48px;
      display: block;
      border: 1px solid #000;
      border-radius: 0;
      padding: 0 16px;
      font-size: 14px;
      line-height: 20px;
      color: $gray;
      cursor: pointer;
      appearance: none;
      background:none;
      z-index: 110;
    }

    &:after {
      position: absolute;
      display: block;
      width: 7px;
      height: 7px;
      transform: rotate(45deg);
      border-right: 2px solid #000;
      border-bottom: 2px solid #000;
      position: absolute;
      top: 19px;
      right: 7px;
      content: '';
    }
  }
}