
#products-list {
  min-height: 400px;
  .products-list-placeholder {
    position: relative;
    .products-loader {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.4);
      text-align: center;
      z-index: 230;
    }
    .pl-data-url {
      cursor: pointer;
    }

    .list-loader {
      align-items: flex-start;
      padding-top: 69px;
      min-height: 180px;
    }
  }
}

.stock-popup {
  display: none;
  background: #fff;
  padding: 24px;
  min-width: 340px;
  position:absolute;
  top: 0px;
  left: 0px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  z-index: 100;

  @include media-breakpoint-up(md) {
    min-width: 430px;
  }

  > .title {
    font-family: $font-bold;
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 24px;
  }

  .stores {
    font-size: 16px;
    line-height: 22px;
    .store {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
      .title {
        margin-right: auto;
      }
    }
  }
}

.stock:hover {
  > .stock-popup {
    @include media-breakpoint-up(lg) {
      display:block;
    }
  }
}

.list-loader {
  @include loader;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba($orange, 0.1);
  z-index: 800;
  
  @include media-breakpoint-down(md) {
    position: fixed;
    width: 100%;
    z-index: 800;
  }

  &.white {
    position: unset;
    @include loaderWhiteSmall;
  }
}

.pagination {
  margin-bottom: 120px;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    li {
      padding: 0;
      margin: 0;

      a,
      span {
        display: block;
        width: 64px;
        height: 64px;
        line-height: 64px;
        border-bottom: 4px solid transparent;
        font-size: 21px;
        line-height: 29px;
        text-align: center;

        @include media-breakpoint-up(md) {
          width: 64px;
          height: 64px;
          line-height: 64px;
        }
      }

      a:hover,
      .pagination-active {
        border-color: $orange;
        color: $orange;
        font-family: $font-bold;
        text-decoration: none;
      }
    }
  }
}

.product-category-content {
  margin-bottom: 72px;
}