$white: #ffffff;
$primary: #02020a;
$black: #02020a;
$orange: #e87722;
$red: #BA5F1B;
$gray: #868686;
$gray2: #737b7d;
$gray3: #868686;
$gray4: #434447;
$lightgray: #F9FBFC;
$lightgray2: #eaecee;
$lightgray3: #f6f8fb;
$lightgray4: #f2f2f2;
$lightgray5: #f6f8fb;
$lightgray6: #cfd2d5;


$green: #00893a;
$blue: #2f80ed;
$purple: #9b51e0;
$red2: #eb5757;
$notification: #279C68;

.orange {color:$orange;}
.gray {color:$gray;}
.bold { font-family: $font-bold; }