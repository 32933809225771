//DA
.header-container {
  position: sticky;
  top: 0;
  z-index: 1003;
  .top-header {
    background-color: $black;
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
    }

    .top-header-wrapper {
      padding-top: 13px;
      padding-bottom: 13px;

      a {
        color: $orange;
        @include orangeHover;
      }

      .phone {
        &:before {
          @include iconPhone;
          margin-right: 8px;
        }
      }

      .mail {
        &:before {
          @include iconMail;
          margin-right: 8px;
        }
      }

      .icon-user-orange {
        margin-right: 4px;
      }

      .current-user {
        color: $orange;
        margin-right: 4px;
      }

      .separator {
        width: 1px;
        height: 28px;
        content: '';
        background-color: $lightgray;
        margin-left: 8px;
        margin-right: 8px;
      }
    }
  }

  .header {
    padding-top: 24px;
    padding-bottom: 24px;
    background: $white;

    .header-wrapper {
      flex-direction: row;
      row-gap: 24px;

      @include media-breakpoint-up(lg) {
        row-gap: 0;
      }

      .hamburger {
        order: 1;

        @include media-breakpoint-up(lg) {
          order: unset;
        }
      }

      .logo {
        order: 2;
        margin-right: auto;
        
        @include media-breakpoint-up(lg) {
          margin-right: 0;
          order: 1;
        }

        img {
          height: 49px;
          width: auto;

          @include media-breakpoint-up(lg) {
            width: 110px;
            height: 72px;
          }
        }
      }
      .header-search {
        order: 3;

        @include media-breakpoint-up(lg) {
          order: 2;
        }
      }
      .account {
        order: 2;

        @include media-breakpoint-up(lg) {
          order: 3;
        }
      }
    }

    .account {
      
      .acc-item  {
        margin-right: 24px;
        text-align: center;
        position: relative;
        @include blackHover;

        @include media-breakpoint-up(lg) {
          margin-right: 32px;
        }

        &:last-of-type {
          margin-right: 0;
        }

        .icon-wrapper {
          position: relative;
        }

        .count {
          position: absolute;
          min-width: 20px;
          min-height: 20px;
          padding-left: 4px;
          padding-right: 4px;
          content: '';
          background-color: $orange;
          border-radius: 50%;
          top: -10px;
          right: -10px;
          color: $white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 10px;
          line-height: 14px;
        }

        &.order {
          margin-right: 0;

          @include media-breakpoint-up(lg) {
            margin-right: 32px;
          }
        }

        &.active {
          background-size: 100% var(--bg-h);
          background-position-x: left;
        }

        .icon-user,
        .icon-cart,
        .icon-inquiry {
          @include media-breakpoint-up(lg) {
            width: 30px;
            height: 30px;
          }
        }

        .help-tooltip {
          top: 72px;
        }

        &:hover {
          @include media-breakpoint-up(md) {
            .help-tooltip {
              display: block;
            }
          }
        }

        &.control-panel {
          .help-tooltip {
            left: -187px;

            &::before {
              left: auto;
              right: 27px;
            }
          }
        }
      }

      span {
        font-family: $font-bold;
        font-size: 12px;
        line-height: 17px;
        color: $black;

        @include media-breakpoint-up(lg) {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }
}

#hamburger {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 9px;
  cursor: pointer;
  z-index: 1001;

  .hamburger-inner {
    width: 32px;
    height: 32px;
    position: relative;

    .line {
      width: 32px;
      height: 3px;
      content: '';
      background-color: $black;
      position: absolute;

      &.first {
        top: 33%;
        transition: all 0.3s ease;
      }

      &.second {
        top: 66%;
        transition: all 0.3s ease;
      }
    }

    &.open {
      .first {
        top: 50%;
        transform: rotate(45deg);
      }
      .second {
        top: 50%;
        transform: rotate(-45deg);
      }
    }
  }

  &.open {
    background-color: $lightgray3;
  }
  
}