.header-search {
  display: flex;
  align-items: center;
  
  .search {
    position: relative;
    margin-bottom: 0;
    width: 100%;

    button {
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      width: 48px;
      height: 48px;
      background-color: $white;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      cursor: pointer;
      border-top: 1px solid $lightgray2;
      border-right: 1px solid $lightgray2;
      border-bottom: 1px solid $lightgray2;
    }

    button {
      position: absolute;
    }
    .search-form-container {
      .search-input-group {
        position: relative;
        .form-group {
          margin-bottom: 0;
          input {
            width: 100%;
            color: $gray;
            padding-top: 12px;
            padding-right: 16px;
            padding-bottom: 12px;
            padding-left: 16px;
            font-family: $font-primary;
            font-size: 16px;
            line-height: 22px;
            border: 1px solid $lightgray2;
            max-width: none;
          }
        }

        .smart-search-results {
          position: absolute;
          top: 48px;
          left: 0;
          width: 100%;
          z-index: 1001;
          background: $white;
          box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
          max-height: 500px;
          overflow: auto;

          @media screen and(min-height:875px) {
            max-height: none;
          }

          .results {
            padding: 12px;
          }
          
          .single-result {
            padding-top: 8px;
            padding-bottom: 8px;

            .img-wrapper {
              width: 48px;
              height: 48px;
              margin-right: 8px;

              img {
                width: 48px;
                height: 48px;
              }
            }

            .title {
              max-width: 60%;
            }

            .price-wrapper {
              margin-left: auto;
              font-family: $font-bold;
              position: relative;
              min-width: 24px;

              .price {
                display: flex;
                flex-direction: column;
              }

              .price,
              .novat {
                white-space: nowrap;

                @include media-breakpoint-up(md) {
                  margin-left: 8px;
                }
              }

              .novat {
                color: $red;
                text-decoration: line-through;
              }

              .list-loader {
                left: 6px;
                top: -12px;
                position: absolute;

                .inner {
                  width: 24px;
                  height: 24px;

                  &:before {
                    border: 3px solid $black;
                  }
                  &:after {
                    border: 3px solid $orange;
                  }
                }
              }
            }

            &:hover {
              background: rgba($gray, 0.1);
            }
          }

          .all-results {
            color: $orange;
            font-family: $font-bold;
            text-decoration: underline;
            cursor: pointer;
            margin-top: 24px;

            &:hover {
              color: $gray4;
              text-decoration: none;
              background: $white;
            }
          }
        }
      }
    }
  }
}

.search-page-container {
  margin-top: 64px;
  margin-bottom: 64px;

  .search-page-title {
    font-size: 37px;
    line-height: 41px;
    margin-bottom: 32px;

  }
  .search-info {
    margin-bottom: 24px;
    display: block;
  }

  .search-products-title {
    font-size: 37px;
    line-height: 41px;
    margin-bottom: 32px;
  }
}