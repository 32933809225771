$container-max-widths: (
  xl: 1584px
);

$grid-gutter-width: 32px;
@media screen and (min-width:1200px) {
  $grid-gutter-width: 48px;
}

.container-fluid {
  max-width: 1920px;
  padding-left: 32px;
  padding-right: 32px;
}

.row {
  margin-right: -32px;
  margin-left: -32px;
}

@import "settings/bootstrap";
@import "settings/fonts";
@import "settings/colors";
@import "settings/mixins";
@import "settings/forms";
@import "settings/icons";

@import "components/splide";
@import "components/header";
@import "components/footer";
@import "components/cookie-consent";
@import "components/modal";
@import "components/main-navi";
@import "components/news-tile";


@import "pages/search";
@import "pages/default";
@import "pages/account";
@import "pages/checkout";
@import "pages/cart";
@import "pages/login";

@import "products/product";
@import "products/product-browse";
@import "products/product-show";

@import "../css/jquery.fancybox.scss";
@import "../css/fancybox.css";
