.single-news,
.service-tile {
    padding: 16px;
    background-color: rgba($lightgray4, 0);
    position: relative;

    @include media-breakpoint-up(md) {
        padding: 25px;
    }


    a {
        color: $black;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
    }

    .image {
        margin-bottom: 16px;
    }

    .content {
        padding-left: 0;
        padding-right: 0;
        .content-wrapper {
            padding: 0;
        }
        .category {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 4px;
        }

        h2 {
            font-size: 20px;
            line-height: 23px;
            margin-bottom: 16px;

            @include media-breakpoint-up(lg) {
                font-size: 28px;
                line-height: 32px;
            }
        }

        .content-edit {
            font-weight: 400;
        }
    }

    &:hover {
        background-color: rgba($lightgray4, 0.5);

        h2 {
            color: $orange;
            text-decoration: underline;
        }
    }
}

.service-tile {
    .image {
        position: relative;

        .hover-img {
            position: absolute;
            top: 0;
            left: 0;
            background-color: $orange;
            width: 100%;
            height: 100%;
            display: none;
            justify-content: center;
            align-items: center;
        }
    }

    &:hover {
        background-color: $white;
        .hover-img {
            display: flex;
        }
    }
}