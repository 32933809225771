.product-show {
  .shop-container {
    padding-bottom: 48px;
    display: grid;
    grid-template-columns: 100%;
    grid-template-areas:
        "title"
        "big-image"
        "data";
        
    @include media-breakpoint-up(md) {
      gap: 20px 0;
      grid-template-columns: 20% 80%;
      grid-template-areas:
      "small-images big-image"
      "small-images big-image"
      "title title"
      "data data";
    }
    
    @include media-breakpoint-up(lg) {
      padding-bottom: 96px;
      grid-template-rows: 1fr;
      grid-template-columns: 120px 400px 1fr;
      gap: 0px;
      grid-template-areas:
      "small-images big-image title"
      "small-images big-image data";
    }
    
    @include media-breakpoint-up(xl) {
      grid-template-columns: 8.333% 33.333% 58.333%;
    }
  }

  #big-image {
    @include media-breakpoint-up(lg) {
      margin-bottom: 32px;
      grid-area: big-image;
      grid-row-end: span 2;
    }
  }
  
  img {
   
    /*
    @include media-breakpoint-up(lg) {
      height: 100%;
      width: 100%;
    }*/
  }
  
  #small-images-container {
    @include media-breakpoint-up(lg) {
      grid-area: small-images;
      grid-row-end: span 2;
    }
    .splide__slide{
      border: 1px solid transparent;
      
      &.is-active {
        border: 1px solid #ccc;
      }
    }
  }
  
  .title-container {
    grid-area: title;
    @include media-breakpoint-up(lg) {
      padding-left: 8.333%;    
    }
    
    h1 {
      font-size: 24px;
      line-height: 28px;
      font-family: $font-bold;
      margin-top: 0;
      margin-bottom: 16px;
  
      @include media-breakpoint-up(lg) {
        font-size: 38px;
        line-height: 44px;
      }
    }
  
    .product-code {
      color:$gray;
      margin-bottom: 24px;
      padding-bottom: 24px;
      border-bottom: 1px solid $gray;
    }

  }
  
  .data-container {
    @include media-breakpoint-down(md) {
      margin-top: 40px;
    }

    grid-area: data;

    @include media-breakpoint-up(lg) {
      padding-left: 8.333%;    
    }
    
    .stock-container {
      position:relative;
      margin-bottom: 24px;
      .stock-check {
        color:$orange;
        font-size: 16px;
        font-family: $font-bold;
        text-decoration: underline;

        &:hover {
          + .stock-popup {
            display: block;
          }
        }
      }
    }
    
    .delivery-container {
      border-bottom: 1px solid $gray;
      margin-bottom: 24px;
      padding-bottom: 15px;
    }

    .price-container {
      
      .price-row {
        .price {
          @include media-breakpoint-up(lg) {
            margin-left: auto;
            margin-right: 97px;
          }
        }
      }
      
      .price-row,
      .rabate-row,
      .your-price-row {
        margin-bottom: 32px;
      }
      
      .your-price-row {
        
        .your-prices {
          text-align:right;
          @include media-breakpoint-up(md) {
            text-align: left;
          }
          .your-price {
            margin-left: auto;
            font-size: 22px;
            line-height: 25px;
            margin-bottom: 8px;
            @include media-breakpoint-up(md) {
              margin-bottom: 0px;
              margin-right: 16px;
              font-size: 28px;
              line-height: 32px;
            }
          }
        }
      }
    }

    .product-preview {
      margin-bottom: 22px;

      * {
        font-size: 14px;
      }
      ul {
        li {
          margin-bottom: 10px;
          list-style: none;

          &:before {
            content:'';
            display: inline-block;
            width: 11px;
            height: 8px;
            margin-right: 6px;
            background: url("data:image/svg+xml;charset=UTF-8,%3csvg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M3.73609 7.84288L0.161085 4.35628C-0.0536949 4.14681 -0.0536949 3.80718 0.161085 3.59769L0.938884 2.8391C1.15366 2.62961 1.50193 2.62961 1.71671 2.8391L4.125 5.18782L9.28329 0.157101C9.49807 -0.0523671 9.84634 -0.0523671 10.0611 0.157101L10.8389 0.915689C11.0537 1.12516 11.0537 1.46479 10.8389 1.67428L4.51391 7.8429C4.29911 8.05237 3.95087 8.05237 3.73609 7.84288Z' fill='%23976391'/%3e%3c/svg%3e") no-repeat center center / contain;
          }
        }
      }
    }

    .add-to-cart-container {
      margin-bottom: 35px;
      .quantity {
        margin: 0 16px 0 0;
        position: relative;
        display: flex;
        border: 1px solid $gray;
        justify-content: space-between;
        
        .btn {
          position:relative;
          height: 46px;
          width: 35px;
          min-width: 0px;
          border: none;
          background: none;
          cursor: pointer;

          @include media-breakpoint-up(sm) {
            position:absolute;
            top: 1px;
            left: 0px;
          }

          &.btn-plus {
            right: 0px;
            left: auto;

            &:before, &:after {
              position: absolute;
              top: 17px;
              right: 20px;
              content: '';
              height: 12px;
              width: 2px;
              background-color: $black;
            }
            
            &:before {
              transform: rotate(90deg);
            }
          }

          &.btn-minus {
            &:before {
              position: absolute;
              top: 22px;
              left: 11px;
              content: '';
              height: 2px;
              width: 12px;
              background-color: $black;
            }
          }
        }

        input {
          width: 50px;
          height: 48px;
          border: none;
          font-size: 16px;
          text-align:center;

          @include media-breakpoint-up(sm) {
            width: 140px;
            padding-left: 45px;
            padding-right: 45px;
          }
        }
        
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        
        input[type=number] {
          -moz-appearance: textfield;
        }
      }
      
      .buttons {
        margin-left: auto;
        @include media-breakpoint-down(sm) {
          max-width: 100%;
          flex: 0 0 100%;
          margin-top: 16px;
        }

        a.btn {
          padding-left: 16px;
          padding-right: 16px;

          .icon {
            margin-left: 8px;
          }
        }

        .inquiry {
          .icon-inquiry {
            transition: all 0.3s ease;
          }
          
          &:hover {
            .icon-inquiry {
              background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_37_14134)'%3E%3Cpath d='M3 4C3 2.9 3.89 2 5 2H14V4H5V18H9.83L10.41 18.58L12 20.17L13.58 18.59L14.17 18H19V10H21V18C21 19.1 20.1 20 19 20H15L12 23L9 20H5C3.89 20 3 19.1 3 18V4Z' fill='%23ffffff'/%3E%3Cpath d='M24 5V3H21V0H19V3H16V5H19V8H21V5H24Z' fill='%23ffffff'/%3E%3Cpath d='M13 15H11V17H13V15Z' fill='%23ffffff'/%3E%3Cpath d='M14 9C14 7.9 13.1 7 12 7C10.9 7 10 7.9 10 9H8C8 6.79 9.79 5 12 5C14.21 5 16 6.79 16 9C16 11.5 13 11.75 13 14H11C11 10.75 14 11 14 9Z' fill='%23ffffff'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_37_14134'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A") no-repeat center center/cover;
            }
          }
        }

        .inquiry a {
          margin-left: 16px;

          @include media-breakpoint-only(xs) {
            width: 48px;
            height: 48px;
            border-radius: 50%;
          }

          .icon {
            margin-left: 0;
            @include media-breakpoint-up(sm) {
              margin-left: 8px;
            }
          }
        }
      }

      .unit {
        color:$gray;
      }
    }
  }

  .description {
    padding-top: 32px;
    padding-bottom: 32px;
    margin-bottom: 96px;
    background: $gray;

    .nav-pills {
      display: flex;
      padding-bottom: 32px;
      margin-bottom: 32px;
      border-bottom: 1px solid $gray;
      overflow-x: auto;

      .nav-item {
        margin-right: 40px;

        a {
          white-space: nowrap;
        }

        &:last-of-type {
          margin-right: 0;
        }
      }

      .nav-link {
        font-family: $font-primary;
        font-size: 16px;
        line-height: 21px;
        color: $gray;
        letter-spacing: 1px;
        text-transform: uppercase;

        &:hover {
          color: $black;
        }

        &.active {
          color: $black;
        }
      }
    }
  }

  .modal-backdrop.show {
    opacity: .75;
  }
  
  .product-inner {
    background: #fff;
    position: relative;
    padding: 24px 0;
    height: 100%;
    overflow: hidden;

    .favourite {
      bottom: 24px;
      right: 15px;
      position: absolute;
      z-index: 230;
      
      @include media-breakpoint-up(sm) {
        top: 35px;
        bottom: auto;
      }
      
      @include media-breakpoint-up(lg) {
        opacity: 0;
        visibility: hidden;
        transform: translate(20px,0);
        transition: all 0.35s;
      }
      
      @include media-breakpoint-up(xl) {
        top: 50px;
        right: 26px;
      }

      .fav-text {
        font-size: 16px;
        line-height: 18px;
        background:#fff;
        padding: 5px 10px;
        color:$black;
        border: $red;
        display: none;
        opacity: 0;
        transform: translate(20px,0);
        transition: all 0.35s;

        @include media-breakpoint-up(lg) {
          display: block;
        }

        @include media-breakpoint-up(xl) {
          font-size: 18px;
          line-height: 30px;
          padding: 4px 10px;
        }
      }

      .fav-icon {
        position: relative;
        z-index: 100;
        width: 38px;
        height: 38px;
        display: block;
        border-radius: 50%;
        text-align:center;
        line-height: 38px;
        background: none;
        transition: background 0.25s;

        @include media-breakpoint-up(md) {
          width: 48px;
          height: 48px;
        }

        &:after {
          content: '';
          width: 20px;
          height: 22px;
          display: block;
          background: url("data:image/svg+xml;charset=UTF-8,%3c?xml version='1.0' encoding='UTF-8'?%3e%3csvg fill='none' viewBox='0 0 28 24' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='m24.451 1.7311c-3.0831-2.6029-7.8464-2.2119-10.796 0.80335-2.9498-3.0152-7.7131-3.4116-10.796-0.80335-4.0112 3.3902-3.4245 8.9172-0.56541 11.847l9.356 9.5706c0.5334 0.5463 1.2481 0.8516 2.0056 0.8516 0.7627 0 1.4722-0.2999 2.0056-0.8462l9.3559-9.5706c2.8537-2.9296 3.4511-8.4566-0.5654-11.852zm-1.2589 10.042-9.3559 9.5706c-0.128 0.1285-0.2347 0.1285-0.3627 0l-9.3559-9.5706c-1.9469-1.9924-2.3416-5.7628 0.38939-8.071 2.0749-1.7513 5.2754-1.4889 7.281 0.56235l1.8669 1.912 1.8669-1.912c2.0163-2.0619 5.2167-2.3137 7.281-0.5677 2.7257 2.3083 2.3203 6.1001 0.3893 8.0764z' fill='%23444'/%3e%3c/svg%3e") no-repeat center center / contain;

          @include media-breakpoint-up(md) {
            width: 28px;
            height: 22px;
          }
        }

        &:hover {
          background: $red;

          &:after {
            background-image: url("data:image/svg+xml;charset=UTF-8,%3c?xml version='1.0' encoding='UTF-8'?%3e%3csvg fill='none' viewBox='0 0 28 24' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='m24.451 1.7311c-3.0831-2.6029-7.8464-2.2119-10.796 0.80335-2.9498-3.0152-7.7131-3.4116-10.796-0.80335-4.0112 3.3902-3.4245 8.9172-0.56541 11.847l9.356 9.5706c0.5334 0.5463 1.2481 0.8516 2.0056 0.8516 0.7627 0 1.4722-0.2999 2.0056-0.8462l9.3559-9.5706c2.8537-2.9296 3.4511-8.4566-0.5654-11.852zm-1.2589 10.042-9.3559 9.5706c-0.128 0.1285-0.2347 0.1285-0.3627 0l-9.3559-9.5706c-1.9469-1.9924-2.3416-5.7628 0.38939-8.071 2.0749-1.7513 5.2754-1.4889 7.281 0.56235l1.8669 1.912 1.8669-1.912c2.0163-2.0619 5.2167-2.3137 7.281-0.5677 2.7257 2.3083 2.3203 6.1001 0.3893 8.0764z' fill='%23fff'/%3e%3c/svg%3e");
          }

          + .fav-text {
            opacity: 1;
            transform: translate(0px,0);
          }
        }
      }
    }

    .image {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
      padding: 20px 0 0;

      @include media-breakpoint-up(sm) {
        padding: 0 24px;
        max-width: none;
        flex: 0 0 100%;
        max-height: 185px;
      }
      
      @include media-breakpoint-up(md) {
        max-height: 230px;
      }

      .label {
        position: absolute;
        top: 0;
        left: 0;
        background:$red;
        height: 40px;
        text-align: center;
        padding: 0 10px;
        color:#fff;
        font-family: $font-primary;
        line-height: 40px;
      }
    }

    .content {
      @include media-breakpoint-up(sm) {
        text-align:center;     
      }

      &.product-content {
        position: relative;
        padding: 20px 0 0;
        z-index: 220;

        @include media-breakpoint-up(lg) {
          transition: transform 0.3s;
          transform: translate(0,30px);
          background: rgba(255,255,255,.9);
        }
      }

      .add-to-cart {
        margin-left: 25px;
        
        @include media-breakpoint-up(lg) {
          position: absolute;
          left: 50%;
          transform: translate(-50%,0);
          margin-left: 0;
        }

        .cart-icon {
          //@include icon-cart;
        }
      }

      .content-inner {
        padding: 0 25px 25px;

        .title {
          h3 {
            font-family: $font-primary;
            color:$secondary;
            font-size: 20px;
            line-height: 25px;
            margin-bottom: 15px;
          }
        }

        .price-container {
          font-family: $font-primary;
          color:$secondary;

          .price {
            font-size: 22px;
            line-height: 22px;

            @include media-breakpoint-up(sm) {
              font-size: 28px;
              line-height: 31px;
            }
          }
          
          .old-price {
            font-family: $font-primary;
            color: rgba(0,0,0,0.55);
            font-size: 14px;
            line-height: 14px;
            text-decoration: line-through;
            margin-bottom: 6px;

            @include media-breakpoint-up(sm) {
              font-size: 16px;
              line-height: 16px;
              margin-bottom: 0px;
            }
          }
        }
      }
    }

    &:hover {
      .product-content {
        @include media-breakpoint-up(lg) {
          transform: translate(0,-50px);
        }
      }

      .favourite {
        opacity: 1;
        visibility: visible;
        transform: translate(0px,0);
      }
    }
  }
}

.product-added {
  .modal-dialog{
    max-width: 552px;
    width: 100%;
    max-height: 440px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    background: #fff;

    @include media-breakpoint-up(md) {
      height: 100%;
      max-height: 392px;
    }

    .modal-content {
      background: $white;
      border: none;
      padding: 16px;

      @include media-breakpoint-up(md) {
        padding: 24px;
      }

      .modal-header {
        border: none;
        background: $white;
        margin-bottom: 24px;

        .close {
          //@include icon-close;
          margin-right: 0;
        }

        .title { 
          font-size: 30px;
          line-height: 43px;
          color:$primary;
          font-family: $font-primary;

          @include media-breakpoint-up(sm) {
            font-size: 39px;
          }
        }
      }

      .modal-body {
        padding-left: 0;
        padding-right: 0;

        @include media-breakpoint-up(md) {
          padding-left: 22px;
          padding-right: 22px;
        }

        .product {
          .product-content {
            .name {
              h2 {
                font-style: $font-bold;
                font-size: 16px;
                line-height: 21px;
                color: $black;
                margin-bottom: 24px;
              }
            }
            .price {
              .price-new {
                color: $red;
                font-family: $font-bold;
              }
            }
          }
        }
      }

      .modal-footer {
        flex-direction: column;
        padding-bottom: 0;

        @include media-breakpoint-up(sm) {
          flex-direction: row;
        }

        .single-button {
          display: flex;
          justify-content: center;
          align-items: center;

          a {
            width: 100%;
            max-width: 250px;
            display: inline-block;
            text-align: center;
          }
          span {
            white-space: nowrap;
            cursor: pointer;
            max-width: 250px;
            width: 100%;
            display: flex;
            justify-content: center;
            margin-bottom: 12px;

            @include media-breakpoint-up(md) {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

#related-products {
  padding-bottom: 40px;
  position: relative;
  min-height: 300px;

  h2 {
    margin-bottom: 48px;
  }
}

.product-show-breadcrumbs {
  .breadcrumbs {
    margin-top: 48px;
    margin-bottom: 64px;

    .list-item {
      a, span {
        font-size: 14px;
      }
    }
  }
}

.splide__sr {
  display: none;
}

.fancybox__thumbs {
  .carousel__slide {
    .fancybox__thumb {
      border-radius: 0;

      &:after {
        border:2px solid $orange;
        border-radius: 0;
      }
    }
  }
}