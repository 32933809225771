.main-visual {
    position: relative;
    margin-bottom: 64px;
     @include media-breakpoint-up(lg) {
        height: calc(100vh - 253px);
        margin-bottom: 96px;
    }

    .content {
        position: absolute;
        top: 50%;
        transform: translateY(-80%);
        width: 100%;
        p {
            color: $white;
            
        }

        .row {
            text-align: center;

            @include media-breakpoint-up(md) {
                text-align: start;
            }
        }

        h2 {
            color: $white;
            
        }
    }
    
    .main-visual-wrapper {
        height: 100%;

        .home-visual-carousell {
            height: 100%;

            .splide__track {
                height: 100%;
            }
        }

        .visual-element {
            height: 100%;
            position: relative;

            a {
                position: absolute;
                left: 0;
                top: 0;
                z-index: 2;
                width: 100%;
                height: 100%;
            }

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                min-height: 400px;
                
                @include media-breakpoint-up(lg) {
                   max-height: calc(100vh - 363px);
                }
            }
        }
    }

    .main-visual-services {

        padding-top: 48px;
        padding-bottom: 48px;

        @include media-breakpoint-up(lg) {
            position: absolute;
            bottom: 0;
            width: 100%;
            padding: 0;
        }
       

        .row {
           justify-content: center;
            padding-left: 32px;
            padding-right: 32px;
            row-gap: 16px;

            @include media-breakpoint-up(lg) {
                justify-content: flex-end;
                
            }

          
        }

        .single-service {
            background-color: $white;
            height: 106px;
            width: 206px;
            box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
            @include media-breakpoint-up(lg) {
                box-shadow: none;
            }
            a {
                background-color: $white;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .orange {
                    display: block;
                }

                .white {
                    display: none;
                }
            }

            &:hover {
                background-color: $orange;

                a {
                    background-color: $orange;

                    .orange {
                        display: none;
                    }
    
                    .white {
                        display: block;
                    }
                }
            }
        }
    }
}